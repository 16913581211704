import React, { useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { sortOptions } from "../../data/lists";
import { OutlineButton } from "../Button/OutlineButton";
import { Dropdown } from "../Dropdown/Dropdown";
import { ReactComponent as SortIcon } from "../../assets/sort_icon.svg";

export const SortBar = ({
  style,
  setShowAddGameModal,
  disabled,
  loading,
  onChange = () => {},
  sortMode,
}) => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      gap: "8px",
      ...style?.container,
    },
    searchBar: {
      ...style?.searchBar,
    },
    dropdown: {
      maxWidth: "270px",
      ...style?.dropdown,
    },
    icon: {
      ...style?.icon,
    },
    text: {
      ...style?.text,
    },
  };

  return (
    <div style={styles.container}>
      <Dropdown
        style={styles.dropdown}
        prefix={<SortIcon />}
        items={sortOptions()}
        onSelect={onChange}
        value={sortMode}
        allowDeselect={false}
      />
      <div style={{ display: "flex", gap: "8px" }}>
        <OutlineButton variant="square" text="Followed players" />
        <OutlineButton variant="square" onClick={() => setShowAddGameModal(true)}>
          <div style={{ display: "flex", gap: "4px" }}>
            <AddIcon style={{ color: "#333333" }} />
            <div>Add new game</div>
          </div>
        </OutlineButton>
      </div>
    </div>
  );
};
