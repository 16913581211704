import React, { useState } from "react";
import { ReactComponent as Add } from "../../assets/add.svg";

// TODO take an icon as a prop
export const HeartButton = ({ style, onClick, disabled, loading }) => {
  const styles = {
    container: {
      cursor: "pointer",
      userSelect: "none",
      transitionDuration: "0.1s",
      ...style?.container,
    },
    button: {
      ...style?.button,
    },
    buttonIcon: {
      color: "#FFFFFF",
      ...style?.buttonIcon,
    },
  };

  const [clicked, setClicked] = useState(false); // TODO default to whatever data comes from backend

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    setClicked(!clicked);

    onClick(event);
  };

  return (
    <div className="heart-button-container" style={styles.container}>
      <div style={styles.button} onClick={handleClick}>
        <Add style={styles.buttonIcon} />
      </div>
    </div>
  );
};
