import React from "react";
import { Route, Routes as Switch, BrowserRouter as Router } from "react-router-dom";
import GameProfile from "../screens/GameProfile";
import Home from "../screens/Home";
import AppLayout from "../layouts/AppLayout";
import { LoginLayout } from "../layouts/LoginLayout";
import Login from "../screens/Login";
import NotFound from "../screens/NotFound";
import Profile from "../screens/Profile";
import Signup from "../screens/Signup";

export const Routes = () => (
  <Router>
    <Switch>
      <Route
        exact
        path="/login"
        element={
          <LoginLayout>
            <Login />
          </LoginLayout>
        }
      />
      <Route
        exact
        path="/signup"
        element={
          <LoginLayout>
            <Signup />
          </LoginLayout>
        }
      />

      <Route
        path="/user/:userId"
        element={
          <AppLayout>
            <Profile />
          </AppLayout>
        }
      />

      <Route
        path="/game/:gameId"
        element={
          <AppLayout>
            <GameProfile />
          </AppLayout>
        }
      />

      <Route
        exact
        path="/"
        element={
          <AppLayout>
            <Home />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/home"
        element={
          <AppLayout>
            <Home />
          </AppLayout>
        }
      />

      {/* TODO either show not found or redirect to home */}
      <Route
        exact
        path="*"
        element={
          <LoginLayout>
            <NotFound />
          </LoginLayout>
        }
      />
    </Switch>
  </Router>
);
