export const statuses = [
  { label: "Added to list", value: "added_to_list" },
  { label: "In progress", value: "in_progress" },
  { label: "Done for now", value: "done_for_now" },
  { label: "Done playing", value: "done_playing" },
  { label: "Completed", value: "completed" },
  { label: "100% completed", value: "fully_completed" },
  { label: "On hold", value: "on_hold" },
  { label: "Abandoned", value: "abandoned" },
  // { label: "Replaying", value: "replaying" },
  { label: "Not interested", value: "not_interested" },
];

export const ratings = [
  { label: "Must play", value: "must_play" },
  { label: "Recommended", value: "recommended" },
  { label: "Slightly recommended", value: "slightly_recommended" },
  { label: "No opinion", value: "no_opinion" },
  { label: "Not recommended", value: "not_recommended" },
  { label: "Do not play", value: "do_not_play" },
];

export const reviewMap = {
  must_play: "10/10 - Must play",
  recommended: "8/10 - Recommended",
  slightly_recommended: "6/10 - Slightly recommended",
  no_opinion: "4/10 - No opinion",
  not_recommended: "2/10 - Not recommended",
  do_not_play: "0/10 - Do not play",
};

export const playedStatuses = [
  "in_progress",
  "done_for_now",
  "done_playing",
  "completed",
  "fully_completed",
  "on_hold",
  "abandoned",
];

/** Used to determine whether a review should be blurred */
export const completedStatuses = [
  "done_playing",
  "completed",
  "fully_completed",
  "on_hold",
  "not_interested",
];

export const getDefaultGameStatusValues = () => [
  { id: "added_to_list", label: "Added to list", value: false },
  { id: "in_progress", label: "In progress", value: false },
  { id: "done_for_now", label: "Done for now", value: false },
  { id: "done_playing", label: "Done playing", value: false },
  { id: "completed", label: "Completed", value: false },
  { id: "fully_completed", label: "100% completed", value: false },
  { id: "on_hold", label: "On hold", value: false },
  { id: "abandoned", label: "Abandoned", value: false },
  { id: "not_interested", label: "Not interested", value: false },
  { id: "no_status", label: "No status set", value: false },
];

export const getDefaultRatingValues = () => [
  { id: "must_play", label: "Must play", value: false },
  { id: "recommended", label: "Recommended", value: false },
  { id: "slightly_recommended", label: "Slightly recommended", value: false },
  { id: "no_opinion", label: "No opinion", value: false },
  { id: "not_recommended", label: "Not recommended", value: false },
  { id: "do_not_play", label: "Do not play", value: false },
];

export const statusCompletion = () => ({
  added_to_list: "waiting",
  in_progress: "playing",
  done_for_now: "done",
  done_playing: "done",
  completed: "done",
  fully_completed: "done",
  on_hold: "waiting",
  abandoned: "abandoned",
  not_interested: "none",
});

export const sortOptions = () => [
  { label: "Default", value: "default" },
  { label: "Status date", value: "status_date" },
  { label: "Last updated (anyone)", value: "last_updated_anyone" },
  { label: "Last updated (me)", value: "last_updated_self" },
  { label: "Year released", value: "release_date" },
];

export const getYears = () => [
  { label: "2022", value: "2022" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
  { label: "2016", value: "2016" },
  { label: "2015", value: "2015" },
  { label: "2014", value: "2014" },
  { label: "2013", value: "2013" },
  { label: "2012", value: "2012" },
  { label: "2011", value: "2011" },
  { label: "2010", value: "2010" },
  { label: "2009", value: "2009" },
  { label: "2008", value: "2008" },
  { label: "2007", value: "2007" },
  { label: "2006", value: "2006" },
  { label: "2005", value: "2005" },
  { label: "2004", value: "2004" },
  { label: "2003", value: "2003" },
  { label: "2002", value: "2002" },
  { label: "2001", value: "2001" },
  { label: "2000", value: "2000" },
  { label: "1999", value: "1999" },
  { label: "1998", value: "1998" },
  { label: "1997", value: "1997" },
  { label: "1996", value: "1996" },
  { label: "1995", value: "1995" },
  { label: "1994", value: "1994" },
  { label: "1993", value: "1993" },
  { label: "1992", value: "1992" },
  { label: "1991", value: "1991" },
  { label: "1990", value: "1990" },
  { label: "1989", value: "1989" },
  { label: "1988", value: "1988" },
  { label: "1987", value: "1987" },
  { label: "1986", value: "1986" },
  { label: "1985", value: "1985" },
  { label: "1984", value: "1984" },
  { label: "1983", value: "1983" },
  { label: "1982", value: "1982" },
  { label: "1981", value: "1981" },
  { label: "Ancient (BDK)", value: "ancient" },
];
