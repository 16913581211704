import React, { useEffect, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findByKey, move } from "../../helpers/helpers";

export const Draggable = ({ style, id, containerRef, thisRef, onRelease, children }) => {
  const styles = {
    draggable: {
      backgroundColor: "#4AAE9B",
      fontWeight: "normal",
      marginBottom: "10px",
      marginTop: "10px",
      padding: "10px",
      cursor: "grab",
    },
  };

  const draggingObject = useRef(null);

  useEffect(() => {
    const container = containerRef?.current;
    if (!containerRef?.current) {
      return;
    }

    const draggables = containerRef?.current?.querySelectorAll(".draggable") || [];

    draggables.forEach((draggable) => {
      draggable.addEventListener("dragstart", () => {
        console.log("dragging", draggable);
        draggingObject.current = draggable;
        draggable.classList.add("dragging");
        // eslint-disable-next-line no-param-reassign
        draggable.style.backgroundColor = "yellow";
      });

      draggable.addEventListener("dragend", () => {
        console.log("dropping", draggable);
        draggingObject.current = null;
        draggable.classList.remove("dragging");
        // eslint-disable-next-line no-param-reassign
        draggable.style.backgroundColor = "#4AAE9B";

        onRelease(thisRef);
      });
    });
  }, []);

  return (
    <div ref={thisRef} id={id} style={styles.draggable} className="draggable" draggable="true">
      {children}
    </div>
  );
};

Draggable.defaultProps = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Draggable);
