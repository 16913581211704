import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { bulkUpdateGameListsAction } from "../../data/actions/gameListsAction";
import { hideManageListsModalAction } from "../../data/actions/modalAction";
import { createRandomString, filterByProperty, sortObjectList } from "../../helpers/helpers";
import { OutlineButton } from "../Button/OutlineButton";
import { Draggable } from "../DraggableItem/Draggable";
import { DraggableContainer } from "../DraggableItem/DraggableContainer";
import { DraggableItem } from "../DraggableItem/DraggableItem";
import { DraggableList } from "../DraggableList/DraggableList";
import { Modal } from "./Modal";

const ManageListsModal = ({ style, isOpen, gameLists, hideModal, user, bulkUpdateGameLists }) => {
  const closeModal = () => {
    hideModal();
  };

  const styles = {
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      ...style?.innerContainer,
    },
    noListText: {
      fontWeight: "600",
      fontSize: "16px",
    },
  };

  const [newLists, setNewLists] = useState([]);
  const [error, setError] = useState("");
  const itemRef = useRef(newLists);

  useEffect(() => {
    const listsForUser = filterByProperty(gameLists?.data, user?.data?.id, "created_by_id");

    const updatedLists = sortObjectList(listsForUser, "index")?.map((list) => ({
      id: list?.id,
      value: list?.name,
    }));

    itemRef.current = updatedLists;
    setNewLists(updatedLists);
  }, [gameLists?.data, isOpen, user?.id]);

  const addNewListItem = () => {
    // Note: The ID is not a real backend ID for newly created items,
    // but is used for the key when re-ordering
    const tempId = createRandomString(10);
    setError("");
    const updatedLists = [...newLists, { id: tempId, value: "" }];
    itemRef.current = updatedLists;
    setNewLists(updatedLists);
  };

  const validate = () => {
    if (
      newLists?.filter((item) => item?.length === 0 || item?.value?.trim()?.length === 0)?.length >
      0
    ) {
      setError("At least one list is missing a name");
      return false;
    }

    return true;
  };

  const saveUpdatedLists = () => {
    setError("");
    const validated = validate();

    if (!validated) {
      return;
    }

    const listsForUser = filterByProperty(gameLists?.data, user?.data?.id, "created_by_id");

    console.log("saving list", newLists);

    bulkUpdateGameLists(
      newLists.map((newList, index) => ({ ...newList, index })),
      listsForUser,
    );

    closeModal();
  };

  const handleListChange = (newValue) => {
    setError("");

    itemRef.current = newValue;
    setNewLists(newValue);
  };

  return (
    <Modal
      showCloseButton
      showCancelButton
      title="Manage my lists"
      submitText="Save lists"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={saveUpdatedLists}
      // disabled={isDisabled()}
      // loading={games?.posting}
      style={{ modal: { maxHeight: "700px" } }}
    >
      <div style={styles.innerContainer}>
        {error && <div style={{ color: "#CD2626" }}>{error}</div>}
        {newLists?.length === 0 ? (
          <div style={styles.noListText}>No lists created yet</div>
        ) : (
          <DraggableList
            itemRef={itemRef}
            items={newLists}
            onChange={handleListChange}
            placeholder="New list"
          />
        )}
        <OutlineButton variant="square" onClick={addNewListItem} style={{ marginTop: "8px" }}>
          <div style={{ display: "flex", gap: "4px" }}>
            <AddIcon style={{ color: "#333333" }} />
            <div>Add new list</div>
          </div>
        </OutlineButton>
      </div>
    </Modal>
  );
};

ManageListsModal.defaultProps = {
  isOpen: false,
  onSubmit: () => {},
};

const mapStateToProps = (state) => ({
  games: state.games,
  gameLists: state.gameLists,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideModal: hideManageListsModalAction,
      bulkUpdateGameLists: bulkUpdateGameListsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageListsModal);
