import {
  createGame,
  fetchGame,
  fetchGames,
  fetchUser,
  updateGame,
  uploadImage,
} from "../apiClient";
import { beginAction, handleFailure, handleResponse, showMessageOnSuccess } from "./actions";
import { UPDATE_GAME_SUCCESS } from "./types";
import * as type from "./types";

export const fetchGamesAction =
  (includes = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_GAMES_PENDING,
    });

    const getData = async () => {
      let response = null;

      try {
        response = await fetchGames(includes);

        if (response.errors.length > 0) {
          console.log("FETCH_GAMES_FAILURE", response.errors);
          dispatch({
            type: type.FETCH_GAMES_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log("FETCH_GAMES_SUCCESS", response);
          dispatch({
            type: type.FETCH_GAMES_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log("FETCH_GAMES_FAILURE", error);
        dispatch({
          type: type.FETCH_GAMES_FAILURE,
          payload: [error.message],
        });
      }
    };

    getData();
  };

export const fetchGameAction =
  (gameId, includes = []) =>
  (dispatch) => {
    beginAction("FETCH_GAME", dispatch);

    (async () => {
      try {
        const response = await fetchGame(gameId, includes);
        handleResponse(response, "FETCH_GAME", dispatch);
      } catch (error) {
        handleFailure(error, "FETCH_GAME", dispatch);
      }
    })();
  };

export const createGameAction = (gameData) => (dispatch) => {
  console.log("preparing to create game", gameData);

  dispatch({
    type: type.CREATE_GAME_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;
      let imageResponse = null;

      console.log("creating game", gameData);

      // TODO Image stuff should be its own method somewhere

      // Upload image
      const imageData = {
        name: gameData?.image?.name,
        format: gameData?.image?.format,
        width: gameData?.image?.width,
        height: gameData?.image?.height,
        filesize: gameData?.image?.size,
        data: gameData?.image?.data,
        is_public: true,
      };
      imageResponse = await uploadImage(imageData);

      if (imageResponse.errors.length > 0) {
        dispatch({
          type: type.CREATE_GAME_FAILURE,
          payload: imageResponse.errors,
        });
        return;
      }

      // Now create the game

      response = await createGame({
        name: gameData?.name,
        year: gameData?.year,
        description: gameData?.description,
        artwork_id: imageResponse?.data?.data?.id,
        // store_page_url: gameData?.store_page_url,
        // release_date: gameData?.release_date,
      });

      if (response.errors.length > 0) {
        console.log("CREATE_GAME_FAILURE", response.errors);
        dispatch({
          type: type.CREATE_GAME_FAILURE,
          payload: response.errors,
        });
      } else {
        console.log("CREATE_GAME_SUCCESS", response);
        dispatch({
          type: type.CREATE_GAME_SUCCESS,
          payload: {
            ...response?.data,
            data: { ...response?.data?.data, artwork: imageResponse?.data },
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Game successfully created",
            severity: "success",
            id: `CREATE_GAME_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log("CREATE_GAME_FAILURE", error);
      dispatch({
        type: type.CREATE_GAME_FAILURE,
        payload: [error.message],
      });
    }
  };

  postData();
};

export const updateGameAction = (gameId, gameData) => (dispatch) => {
  console.log("preparing to update game", gameData);

  beginAction("UPDATE_GAME", dispatch);

  (async () => {
    try {
      let response = null;
      let imageResponse = null;

      if (gameData?.image) {
        // TODO Image stuff should be its own method somewhere
        // Upload image
        const imageData = {
          name: gameData?.image?.name,
          format: gameData?.image?.format,
          width: gameData?.image?.width,
          height: gameData?.image?.height,
          filesize: gameData?.image?.size,
          data: gameData?.image?.data,
          is_public: true,
        };
        imageResponse = await uploadImage(imageData);

        if (imageResponse.errors.length > 0) {
          dispatch({
            type: type.UPDATE_GAME_FAILURE,
            payload: imageResponse.errors,
          });
          return;
        }
      }

      const newGameData = {
        name: gameData?.name,
        year: gameData?.year,
        description: gameData?.description,
        artwork_id: gameData?.artwork_id,
        // store_page_url: gameData?.store_page_url,
        // release_date: gameData?.release_date,
      };

      if (imageResponse) {
        newGameData.artwork_id = imageResponse?.data?.data?.id;
      }

      // Now update the game
      response = await updateGame(newGameData, gameId);
      handleResponse(response, "UPDATE_GAME", dispatch);
      showMessageOnSuccess(response, "Game successfully updated", UPDATE_GAME_SUCCESS, dispatch);
    } catch (error) {
      handleFailure(error, "UPDATE_GAME", dispatch);
    }
  })();
};
