import { flattenToProperty, pluck } from "../../helpers/helpers";
import { createGameList, deleteGameList, fetchGameLists, updateGameList } from "../apiClient";
import * as type from "./types";

export const fetchGameListsAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_GAME_LISTS_PENDING,
    });

    (async () => {
      let response = null;

      try {
        response = await fetchGameLists(includes, filters);

        if (response.errors.length > 0) {
          console.log("FETCH_GAME_LISTS_FAILURE", response.errors);
          dispatch({
            type: type.FETCH_GAME_LISTS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log("FETCH_GAME_LISTS_SUCCESS", response);
          dispatch({
            type: type.FETCH_GAME_LISTS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log("FETCH_GAME_LISTS_FAILURE", error);
        dispatch({
          type: type.FETCH_GAME_LISTS_FAILURE,
          payload: [error.message],
        });
      }
    })();
  };

/**
 * Update many game lists in one go
 *
 * NewGameLists - the updated list including new indexes
 * OldGameLists - the old values before updating. Items from this not in the new list will be deleted
 */
export const bulkUpdateGameListsAction = (newGameLists, oldGameLists) => (dispatch) => {
  dispatch({
    type: type.BATCH_UPDATE_GAME_LISTS_PENDING,
  });

  (async () => {
    const response = null;

    try {
      console.log("new game lists", newGameLists);
      console.log("old game lists", oldGameLists);

      const oldGameListIds = flattenToProperty(oldGameLists, "id");
      const newGameListIds = flattenToProperty(newGameLists, "id");

      const gameListsToCreate = newGameLists?.filter(
        (newGameList) => !oldGameListIds?.includes(newGameList?.id),
      );

      const gameListsToUpdate = newGameLists?.filter((newGameList) =>
        oldGameListIds?.includes(newGameList?.id),
      );

      const gameListsToDelete = oldGameLists?.filter(
        (oldGameList) => !newGameListIds?.includes(oldGameList?.id),
      );

      console.log("create", gameListsToCreate);
      console.log("update", gameListsToUpdate);
      console.log("delete", gameListsToDelete);

      const createResponse = await Promise.all(
        gameListsToCreate?.map((newGameList) =>
          createGameList({
            index: newGameList?.index,
            name: newGameList?.value,
            is_shared: false,
          }),
        ),
      );

      const createErrors = createResponse?.filter((response) => response?.errors?.length > 0);
      if (createErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_GAME_LISTS_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      const updateResponse = await Promise.all(
        gameListsToUpdate?.map((gameList) =>
          updateGameList(
            {
              index: gameList?.index,
              name: gameList?.value,
            },
            gameList?.id,
          ),
        ),
      );

      const updateErrors = updateResponse?.filter((response) => response?.errors?.length > 0);
      if (updateErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_GAME_LISTS_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      const deleteResponse = await Promise.all(
        gameListsToDelete?.map((gameList) => deleteGameList(gameList?.id)),
      );

      const deleteErrors = deleteResponse?.filter((response) => response?.errors?.length > 0);
      if (deleteErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_GAME_LISTS_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      // If we made it here, it worked

      // console.log("BATCH_UPDATE_GAME_LISTS_SUCCESS", createResponse, updateResponse, deleteResponse);

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: "Game lists successfully updated",
          severity: "success",
          id: `BATCH_UPDATE_GAME_LISTS_SUCCESS_${new Date().getTime()}`,
        },
      });

      dispatch({
        type: type.BATCH_UPDATE_GAME_LISTS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      console.log("BATCH_UPDATE_GAME_LISTS_FAILURE", error);
      dispatch({
        type: type.BATCH_UPDATE_GAME_LISTS_FAILURE,
        payload: [error.message],
      });
    }
  })();
};
