import { mergeArrays, replaceItem } from "../../helpers/helpers";
import * as type from "../actions/types";

const initialState = {
  errors: [],
  fetching: false,
  posting: false,
  success: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_GAME_PENDING:
      return {
        ...state,
        ...initialState,
        loading: true,
        success: false,
        errors: [],
      };
    case type.FETCH_GAME_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, action.payload, "id", true),
      };
    case type.FETCH_GAME_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload,
      };
    case type.FETCH_GAMES_PENDING:
      return {
        ...state,
        ...initialState,
        fetching: true,
        success: false,
        errors: [],
      };
    case type.FETCH_GAMES_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true,
        errors: [],
        data: mergeArrays(state.data, action.payload?.data, "id", true),
      };
    case type.FETCH_GAMES_FAILURE:
      return {
        ...state,
        fetching: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_GAME_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.CREATE_GAME_SUCCESS:
      const newItem = action?.payload?.data;

      console.log("new game", newItem);

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, newItem, "id", true),
      };
    case type.CREATE_GAME_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.UPDATE_GAME_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.UPDATE_GAME_SUCCESS:
      console.log("UPDATE_GAME_SUCCESS", action);
      const updatedItem = action?.payload;
      console.log("updated game", updatedItem);

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, updatedItem, "id", true),
      };
    case type.UPDATE_GAME_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_GAME_USER_SUCCESS:
      const gameUser = action?.payload?.data;

      const updatedGames = state.data?.map((game) => {
        if (game?.id === gameUser?.game_id) {
          console.log("game", game);
          return {
            ...game,
            gameUserIds: {
              data: [{ id: gameUser?.id }, ...(game?.gameUserIds?.data || [])],
            },
          };
        }

        return game;
      });

      // Update the game to have a user

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: updatedGames,
      };
    default:
      return state;
  }
};
