import axios from "axios";
import store, { getStore } from "./store";
// eslint-disable-next-line import/no-cycle
import { clearUserLoginAction } from "./actions/loginAction";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000,
  headers: { "X-Custom-Header": "foobar" },
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.Accept = "application/json";

// TODO if api responds with unauthenticated, wipe token and redirect to login screen

const handleError = (error) => {
  console.log("error: ", error.response);

  // If unauthenticated, redirect to login
  if (error?.response?.status === 401) {
    getStore().dispatch(clearUserLoginAction());
  }

  const multipleErrors = error.response?.data?.errors;
  let errorList = [];

  // Reduce multiple errors (e.g. invalid email, password) down to a list of errors
  if (multipleErrors) {
    let allErrors = [];
    Object.keys(multipleErrors).forEach((loginError) => {
      allErrors = [...allErrors, ...multipleErrors[loginError]];
    });

    errorList = allErrors;
  } else {
    errorList = [error.response?.data?.error];
  }

  return errorList;
};

const getParams = (includes = [], filters = []) => {
  const params = {};

  if (includes?.length > 0) {
    params.include = includes?.join(",");
  }

  if (filters?.length > 0) {
    params.q = JSON.stringify(filters);
  }

  return { params };
};

const fetchItem = async (url, config, includes = [], filters = []) => {
  const responseData = {
    data: null,
    errors: [],
  };

  await axios
    .get(url, { ...config, ...getParams(includes, filters) })
    .then((response) => {
      console.log("response: ", response);
      responseData.data = response.data;
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  return responseData;
};

const post = async (url, data, config, includes) => {
  const responseData = {
    data: null,
    errors: [],
  };

  await axios
    .post(url, data, { ...config, ...getParams(includes) })
    .then((response) => {
      console.log("response: ", response);
      responseData.data = response.data;
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  // TODO if error, show on app somewhere

  return responseData;
};

const patchItem = async (url, id, data, config, includes) => {
  const responseData = {
    data: null,
    errors: [],
  };

  await axios
    .patch(`${url}/${id}`, data, { ...config, ...getParams(includes) })
    .then((response) => {
      console.log("patch response: ", response);
      responseData.data = response.data;
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  // TODO if error, show on app somewhere

  return responseData;
};

const deleteItem = async (url, id, config) => {
  const responseData = {
    errors: [],
  };

  await axios
    .delete(`${url}/${id}`, { ...config })
    .then((response) => {
      console.log("delete response: ", response);
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  // TODO if error, show on app somewhere

  return responseData;
};

const getAuthConfig = () => ({
  headers: {
    Authorization: `Bearer ${getStore()?.getState()?.login?.token}`,
  },
});

// Endpoints
export const login = (data) => post("/login", data);
export const signup = (data) => post("/register", data);
export const fetchGameUsers = (includes = [], filters = []) =>
  fetchItem("/game_users", getAuthConfig(), includes, filters);

// Users
export const fetchSelf = () => fetchItem("/users/me", getAuthConfig());
export const fetchUser = (userId) => fetchItem(`/users/${userId}`, getAuthConfig());
export const fetchUsers = () => fetchItem(`/users`, getAuthConfig());

// Games
export const createGame = (data, includes = []) => post("/games", data, getAuthConfig(), includes);
export const updateGame = (data, id, includes = []) =>
  patchItem("/games", id, data, getAuthConfig(), includes);
export const fetchGame = (gameId, includes = []) =>
  fetchItem(`/games/${gameId}`, getAuthConfig(), includes);
export const fetchGames = (includes = [], filters = []) =>
  fetchItem("/games", getAuthConfig(), includes, filters);

// Images
export const uploadImage = (data, includes = []) =>
  post("/images", data, getAuthConfig(), includes);

// GameUsers
export const createGameUser = (data, includes = []) =>
  post("/game_users", data, getAuthConfig(), includes);
export const updateGameUser = (data, id, includes = []) =>
  patchItem(`/game_users`, id, data, getAuthConfig(), includes);

// Reviews
export const fetchReviews = (includes = [], filters = []) =>
  fetchItem("/reviews", getAuthConfig(), includes, filters);
export const createReview = (data, includes = []) =>
  post("/reviews", data, getAuthConfig(), includes);
export const updateReview = (data, id, includes = []) =>
  patchItem(`/reviews`, id, data, getAuthConfig(), includes);

// Game Lists
export const fetchGameLists = (includes = [], filters = []) =>
  fetchItem("/game_lists", getAuthConfig(), includes, filters);
export const createGameList = (data, includes = []) =>
  post("/game_lists", data, getAuthConfig(), includes);
export const updateGameList = (data, id, includes = []) =>
  patchItem(`/game_lists`, id, data, getAuthConfig(), includes);
export const deleteGameList = (id) => deleteItem(`/game_lists`, id, getAuthConfig());

// Game List Games
export const fetchGameListGames = (includes = [], filters = []) =>
  fetchItem("/game_list_games", getAuthConfig(), includes, filters);
export const createGameListGame = (data, includes = []) =>
  post("/game_list_games", data, getAuthConfig(), includes);
export const updateGameListGame = (data, id, includes = []) =>
  patchItem(`/game_list_games`, id, data, getAuthConfig(), includes);
