import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { theme } from "../../theme";
import { HeartButton } from "../Button/HeartButton";

const CurrentlyPlaying = ({ user, style, games }) => {
  const styles = {
    container: {
      ...style,
      ...style?.container,
    },
    heading: {
      ...theme.h2,
      fontSize: "20px",
      ...style?.heading,
    },
    imageListContainer: {
      marginTop: "16px",
      display: "flex",
      gap: "8px",
      overflowX: "auto",
      ...style?.imageListContainer,
    },
    imageContainer: {
      background: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      ...style?.imageContainer,
    },
    image: {
      width: "280px",
      height: "158px",
      borderRadius: "4px",

      position: "relative",
      overflow: "hidden",
      backgroundColor: "black",
      backgroundSize: "cover",
      backgroundPosition: "center",
      ...style?.image,
    },
    likeButton: {
      position: "absolute",
      right: "16px",
      top: "16px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.heading}>Currently playing</div>
      <div style={styles.imageListContainer}>
        {games.map((game) => (
          <div style={styles.imageContainer}>
            <div
              style={{
                ...styles.image,
                backgroundImage: `url('${game?.artwork?.data?.url}')`,
              }}
            />
            <div style={styles.likeButton}>
              <HeartButton />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // games: state.games,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentlyPlaying);
