import React, { useState } from "react";
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as DownArrow } from "../../assets/down_arrow_filled.svg";
import { statusCompletion, statuses } from "../../data/lists";
import { formatDate, getLabelForValue } from "../../helpers/helpers";
import { theme } from "../../theme";
import { ActionMenu } from "../ActionMenu/ActionMenu";
import UpdateStatusModal from "../Modal/UpdateStatusModal";

const ReviewStatus = ({ game, gameUser, user, style }) => {
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  // const [status, setStatus] = useState(gameUser?.status);
  const [chosenStatus, setChosenStatus] = useState(null); // This is the one the user selects, to pre-fill the form with
  const [showStatusModal, setShowStatusModal] = useState(false);

  const getBackgroundColor = () => {
    if (gameUser?.status) {
      const completion = statusCompletion()?.[gameUser?.status];

      switch (completion) {
        case "done":
          return "#B3D69C";
        case "playing":
          return "#90BBE6";
        case "waiting":
          return "#FFEE74";
        case "abandoned":
          return "#F07F86";
        case "none":
        default:
          return "#FFFFFF";
      }
    }
    return "#FFFFFF";
  };

  const getBorder = () => {
    if (gameUser?.status) {
      return "none";
    }
    return "1px solid #333333";
  };

  const styles = {
    container: {
      ...style,
      ...style?.container,
    },
    containerInner: {
      textAlign: "right",
      ...style?.containerInner,
    },
    reviewStatus: {
      ...theme.normal,
      color: "#000000",
      textAlign: "right",
      display: "flex",
      gap: "4px",
      ...style?.reviewStatus,
    },
    playerName: {
      ...theme.h4,
      flex: 1,
      ...style?.playerName,
    },
    statusContainer: {
      // flex: 1,
      textAlign: "right",
      ...style?.statusContainer,
    },
    status: {
      ...theme.small2,
      border: getBorder(),
      padding: "4px 8px",
      borderRadius: "2px",
      textAlign: "left",
      display: "flex",
      gap: "4px",
      backgroundColor: getBackgroundColor(),
      ...style?.status,
    },
    rating: {
      ...style?.rating,
    },
    description: {
      ...style?.description,
    },
  };

  const isSelf = gameUser?.user_id === user?.data?.id;

  const changeStatus = (item) => {
    console.log("OPENING STATUS CHANGE MODAL");

    unstable_batchedUpdates(() => {
      setShowStatusModal(true);
      setChosenStatus(item?.value);
    });

    //
    // setStatus(item?.value);
    // setReviewStatus(gameUser, item?.value);
  };

  const toggleStatusMenu = () => {
    const canEditStatus = isSelf;
    if (!canEditStatus) {
      setShowStatusMenu(false);
      return;
    }

    setShowStatusMenu(!showStatusMenu);
  };

  return (
    <div style={styles.container}>
      <div style={styles.containerInner}>
        <div
          style={{ ...styles.status, ...(isSelf && theme.clickable) }}
          onClick={toggleStatusMenu}
          // onFocus={() => setShowStatusMenu(!showStatusMenu)}
          tabIndex={0}
          onBlur={() => setShowStatusMenu(false)}
        >
          <div>
            {gameUser?.status
              ? `${getLabelForValue(gameUser?.status, statuses)} - ${formatDate(
                  gameUser?.status_date,
                )}`
              : "Set a status"}
          </div>
          {isSelf && (
            <div
              style={{
                color: "#323232",
                marginTop: "-1px",
              }}
            >
              <DownArrow />
            </div>
          )}
        </div>
      </div>

      <ActionMenu
        isOpen={showStatusMenu}
        onClose={() => setShowStatusMenu(false)}
        onSelect={changeStatus}
        style={{ textAlign: "left" }}
        items={statuses}
      />
      <UpdateStatusModal
        isOpen={showStatusModal}
        onClose={() => setShowStatusModal(false)}
        defaultGameId={game?.id}
        defaultStatus={chosenStatus}
        gameUser={gameUser}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStatus);
