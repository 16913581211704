// eslint-disable-next-line import/no-cycle
import { login as apiLogin } from "../apiClient";
import { LOGIN_USER_SUCCESS } from "./types";
import * as type from "./types";

// export const setUserAction = (user) => (dispatch) => {
//   // dispatch({
//   //   type: type.FETCH_USER_SUCCESS,
//   //   payload: user,
//   // });
// };
//
// export const fetchUserAction = (userId) => (dispatch) => {
//   // dispatch({
//   //   type: type.FETCH_USER_PENDING,
//   // });
// };

export const userLogoutAction = () => (dispatch) => {
  console.log("loggin out");

  // TODO hit endpoint to wipe token from db for security

  dispatch({
    type: type.LOGOUT_USER,
  });
};

export const clearUserLoginAction = () => (dispatch) => {
  console.log("clearing login");
  dispatch({
    type: type.CLEAR_LOGIN_USER,
  });
};

export const userLoginAction =
  ({ email, password }) =>
  (dispatch) => {
    dispatch({
      type: type.LOGIN_USER_PENDING,
      payload: email,
    });

    const getData = async () => {
      let response = null;

      try {
        response = await apiLogin({ email, password });

        if (response.errors.length > 0) {
          console.log("LOGIN_USER_FAILURE", response.errors);
          dispatch({
            type: type.LOGIN_USER_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log("LOGIN_USER_SUCCESS", response);
          dispatch({
            type: type.LOGIN_USER_SUCCESS,
            payload: { email, token: response.data.token },
          });
        }
      } catch (error) {
        console.log("LOGIN_USER_FAILURE", error);
        dispatch({
          type: type.LOGIN_USER_FAILURE,
          payload: [error.message],
        });
      }
    };

    getData();
  };
