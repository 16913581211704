import { createGameListGame, fetchGameListGames } from "../apiClient";
import { beginAction, handleFailure, handleResponse, showMessageOnSuccess } from "./actions";
import * as type from "./types";

export const fetchGameListGamesAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_GAME_LIST_GAMES_PENDING,
    });

    (async () => {
      let response = null;

      try {
        response = await fetchGameListGames(includes, filters);

        if (response.errors.length > 0) {
          console.log("FETCH_GAME_LIST_GAMES_FAILURE", response.errors);
          dispatch({
            type: type.FETCH_GAME_LIST_GAMES_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log("FETCH_GAME_LIST_GAMES_SUCCESS", response);
          dispatch({
            type: type.FETCH_GAME_LIST_GAMES_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log("FETCH_GAME_LIST_GAMES_FAILURE", error);
        dispatch({
          type: type.FETCH_GAME_LIST_GAMES_FAILURE,
          payload: [error.message],
        });
      }
    })();
  };

export const createGameListGameAction = (data) => (dispatch) => {
  beginAction("CREATE_GAME_LIST_GAME", dispatch);

  (async () => {
    try {
      const response = await createGameListGame(data);
      handleResponse(response, "CREATE_GAME_LIST_GAME", dispatch);
      showMessageOnSuccess(
        response,
        "Game has been added to list",
        "CREATE_GAME_LIST_GAME",
        dispatch,
      );
    } catch (error) {
      handleFailure(error, "CREATE_GAME_LIST_GAME", dispatch);
    }
  })();
};
