import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { PrimaryButton } from "../components/Button/PrimaryButton";
import { TextLink } from "../components/Button/TextLink";
import { TextField } from "../components/TextField/TextField";
import { clearUserLoginAction, userLoginAction } from "../data/actions/loginAction";
import store, { getStore } from "../data/store";
import { theme } from "../theme";

const Login = ({ userLogin, login, clearUserLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(login?.errors || []);

  useEffect(() => {
    if (errors) {
      setErrors([]);
    }
  }, [email, password]);

  useEffect(() => {
    setErrors(login?.errors || []);
  }, [login.errors]);

  // TODO check if the user already has a token, otherwise, remove it (from previous sessions)

  useEffect(() => {
    if (login.success && login.token) {
      // Redirect the user since we have a token
      navigate("/home");
    }
  }, [login, store]);

  // // Reset the login state, e.g. if it's loading
  // useEffect(() => {
  //   clearUserLogin();
  // }, []);

  const styles = {
    container: {},
    login: {
      backgroundColor: "#FFFFFF",
      padding: "24px",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "450px",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    errorMessage: {
      color: "red",
      marginBottom: "16px",
    },
  };

  const loginUser = async () => {
    // TODO validation

    // setLoading(true);

    userLogin({ email, password });

    // const response = await apiLogin({ email, password });
    //
    // if (response.error) {
    //   setError(response.error);
    // }
    //
    // if (response.data) {
    //   // Set the token locally
    //   // TODO use redux or something
    //
    //   // Redirect to main page
    //   navigate("/home");
    // }
    //
    // setLoading(false);
  };

  return (
    <div>
      <div style={styles.login}>
        <div style={{ marginBottom: "8px", ...theme.h2 }}>Log in</div>
        <div
          style={{
            marginBottom: "24px",
            fontSize: "12px",
            display: "flex",
            gap: "4px",
          }}
        >
          <div>Not a member yet?</div>
          <TextLink
            onClick={() => {
              setErrors([]);
              navigate("/signup");
            }}
          >
            Sign up
          </TextLink>
        </div>
        {errors.length > 0 && (
          <div style={styles.errorMessage}>
            <div style={{ fontWeight: "bold" }}>There were errors logging in:</div>
            {errors.map((error) => (
              <div>{error}</div>
            ))}
          </div>
        )}
        <TextField
          label="Email"
          value={email}
          onChange={setEmail}
          placeholder="example@gmail.com"
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={setPassword}
          placeholder="********"
          style={{ marginBottom: "8px" }}
        />

        <TextLink style={{ fontSize: "12px", marginBottom: "24px" }}>Forgot password</TextLink>
        <PrimaryButton
          variant="square"
          text="Log in"
          onClick={loginUser}
          loading={login.loading}
          // disabled={password === "" || email === ""}
          style={{
            display: "inline-block",
            minWidth: "106px",
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: userLoginAction,
      clearUserLogin: clearUserLoginAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
