import React from "react";
import { theme } from "../../theme";

export const reviewCardStyles = ({ style, game }) => ({
  container: {
    ...style,
    position: "relative",
    ...style?.container,
  },
  gameCard: {
    // display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    minHeight: "198px",
    // overflow: "hidden",
    ...style?.gameCard,
  },
  imageContainer: {
    position: "relative",
    // width: "186px",
    width: "100%",
    height: "198px",
    overflow: "hidden",
    backgroundColor: "black",
    backgroundImage: `url('${game?.artwork?.data?.url}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    ...style?.imageContainer,
  },
  image: {
    justifyContent: "center",
    // width: "186px",
    // maxHeight: "198px",
    width: "100%",
    minWidth: "100%",
    minHeight: "198px",
    objectFit: "fill",
    backgroundColor: "black",
    objectPosition: "50% 50%",
    WebkitUserDrag: "none",
    userSelect: "none",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    ...style?.image,
  },
  heartButton: {
    position: "absolute",
    right: "16px",
    top: "16px",
    ...style?.heartButton,
  },
  updateText: {
    position: "absolute",
    cursor: "pointer",
    userSelect: "none",
    display: "inline-flex",
    gap: "10px",
    color: "white",
    bottom: 0,
    left: 0,
    width: "186px",
    marginBottom: "10px",
    // left: "50%",
    justifyContent: "center",
    transitionDuration: "0.1s",
    opacity: 0,
    ...style?.updateText,
  },
  details: {
    position: "relative",
    ...style?.details,
  },
  name: {
    ...theme.h2,
    ...style?.name,
  },
  reviews: {
    // display: "flex",
    width: "100%",
    // gap: "16px",
    ...style?.reviews,
  },
  reviewContainer: {
    position: "relative",
    // display: "flex",
    gap: "8px",
    // height: "31px",
    padding: "24px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    maxWidth: "408px", // 456 but flexbox weirdness (48px padding)
    minWidth: "408px",
    ...theme.card,
    ...style?.reviewContainer,
  },
  playerName: {
    ...theme.h2,
    flex: 1,
    ...theme.clickable,
    ...style?.playerName,
  },
  reviewText: {
    position: "relative",
    overflow: "hidden",
    overflowWrap: "break-word",
    whiteSpace: "pre-line",
    ...style?.reviewText,
  },
  rating: {
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "16px",
    marginBottom: "24px",
    ...style?.rating,
  },
  description: {
    ...style?.description,
  },
});
