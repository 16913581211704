import React, { forwardRef, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { COLORS } from "../../theme";

export const DatePicker = ({
  style,
  label,
  disabled,
  value,
  loading,
  placeholder,
  onSelect,
  autofocus,
  onBlur,
  range,
}) => {
  // const [startDate, setStartDate] = useState(new Date());

  const ref = useRef();
  const handleOnChange = (newDate) => {
    console.log("change", newDate);
    // Can't change if not editable
    if (disabled) {
      return;
    }

    // const newValue = event.target.value;

    onSelect(newDate);
  };

  // Auto focus
  useEffect(() => {
    // The timeout is because inputs revealed on click (e.g. in modals) have
    // their inputs stolen by the click
    if (ref.current && autofocus) {
      setTimeout(() => {
        console.log("focusing");
        ref.current.focus();
      }, 50);
    }
  }, []);

  const styles = {
    container: {
      ...style,
      position: "relative",
      width: "100%",
      ...style?.container,
    },
    label: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#333333",
      marginBottom: "8px",
      ...style?.label,
    },
    textContainer: {
      userSelect: "none",
      whiteSpace: "nowrap",
      padding: "13px 16px",
      fontSize: "14px",
      lineHeight: "14px",
      ...style?.text,
    },
    dropdown: {
      position: "relative",
      borderRadius: "4px",
      minHeight: "42px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      ...style?.dropdown,
    },
    dropdownClosed: {
      height: "40px",
      border: "1px solid #D4D4D4",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      ...style?.dropdownClosed,
    },
    icon: {
      width: "16px",
      height: "16px",
      marginLeft: "auto",
      padding: "13px 16px",
      color: COLORS.gray,
      ...style?.icon,
    },
  };

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div style={styles.dropdown} onClick={onClick} tabIndex={0}>
      <div style={styles.dropdownClosed}>
        <div style={styles.textContainer}>{value}</div>
        <CalendarIcon style={styles.icon} />
      </div>
    </div>
  ));

  return (
    <div style={styles.container}>
      {label && <div style={styles.label}>{label}</div>}
      <ReactDatePicker
        ref={ref}
        onBlur={onBlur}
        // className="date-input"
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        tabIndex={0}
        disabled={disabled}
        selected={value}
        dateFormat="dd/MM/yyyy"
        customInput={<DatePickerInput />}
        selectsRange={range}
      />
    </div>
  );
};

DatePicker.defaultProps = {
  loading: false,
  placeholder: "Start typing...",
  onSelect: () => {},
  onBlur: () => {},
  autofocus: false,
  range: false,
};
