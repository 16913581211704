import { throttle } from "lodash/function";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { loadState, saveState } from "./localStorage";
import rootReducer from "./reducers/rootReducer";

let store = null;

export default () => {
  const persistedState = loadState();

  // console.log("CREATING STORE");

  const middleware = [thunk];
  store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

  store.subscribe(
    throttle(() => {
      // console.log("setting token from store", store.getState());
      saveState({
        login: {
          token: store.getState()?.login?.token,
        },
      });
    }, 1000),
  );

  return store;
};

export const getStore = () => store;
