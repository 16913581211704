import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userLogoutAction } from "../../data/actions/loginAction";
import { showManageListsModalAction } from "../../data/actions/modalAction";
import { theme } from "../../theme";
import { ActionMenu } from "../ActionMenu/ActionMenu";
import { DropdownButton } from "../Button/DropdownButton";
import { TextButton } from "../Button/TextButton";

const Header = ({ userLogout, user, showManageListsModal }) => {
  const navigate = useNavigate();
  const [showActionMenu, setShowActionMenu] = useState(false);

  const logout = () => {
    userLogout();
    navigate("/login");
  };

  const styles = {
    container: {
      position: "fixed",
      zIndex: "10",
      top: "0",
      height: "53px",
      paddingTop: "15px",
      paddingBottom: "15px",
      // padding: "15px 24px",
      width: "100%",
      backgroundColor: "white",
      borderBottom: "1px solid #D4D4D4",
      alignItems: "center",
    },
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // height: "53px",
          margin: "0 auto",
          width: "calc(100% - 48px)",
          maxWidth: "1400px",
          alignItems: "center",
        }}
      >
        <div
          style={{ ...theme.h2, cursor: "pointer", userSelect: "none" }}
          onClick={() => navigate("/")}
        >
          C&M Game List
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <TextButton onClick={() => navigate("/")}>Master list</TextButton>
          <TextButton onClick={() => navigate(`/user/${user?.data?.id}`)}>My profile</TextButton>
          <TextButton>Friends</TextButton>
        </div>
        <div>
          <DropdownButton onClick={() => setShowActionMenu(!showActionMenu)}>
            {user?.data?.username}
          </DropdownButton>
          <ActionMenu
            isOpen={showActionMenu}
            onClose={() => setShowActionMenu(false)}
            items={[
              { label: "Edit profile" },
              { label: "Edit game lists", onClick: showManageListsModal },
              { label: "App settings" },
              { label: "Log out", onClick: logout },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogout: userLogoutAction,
      showManageListsModal: showManageListsModalAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
