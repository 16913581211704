import { pluck } from "../../helpers/helpers";
import { createGameUser, fetchGameUsers, updateGameUser } from "../apiClient";
import * as type from "./types";

export const fetchGameUsersAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_GAME_USERS_PENDING,
    });

    const getData = async () => {
      let response = null;

      try {
        response = await fetchGameUsers(includes, filters);

        if (response.errors.length > 0) {
          console.log("FETCH_GAME_USERS_FAILURE", response.errors);
          dispatch({
            type: type.FETCH_GAME_USERS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log("FETCH_GAME_USERS_SUCCESS", response);
          dispatch({
            type: type.FETCH_GAME_USERS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log("FETCH_GAME_USERS_FAILURE", error);
        dispatch({
          type: type.FETCH_GAME_USERS_FAILURE,
          payload: [error.message],
        });
      }
    };

    getData();
  };

export const createGameUserAction = (gameUserData) => (dispatch) => {
  console.log("preparing to create game user", gameUserData);

  dispatch({
    type: type.CREATE_GAME_USER_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;

      // Create the game user

      response = await createGameUser(
        pluck(gameUserData, [
          "game_id",
          "user_id",
          "status",
          "is_favourite",
          "status_date",
        ]),
        ["user"],
      );

      if (response.errors.length > 0) {
        console.log("CREATE_GAME_USER_FAILURE", response.errors);
        dispatch({
          type: type.CREATE_GAME_USER_FAILURE,
          payload: response.errors,
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Failed to update game status",
            severity: "error",
            id: `CREATE_GAME_USER_FAILURE_${new Date().getTime()}`,
          },
        });
      } else {
        console.log("CREATE_GAME_USER_SUCCESS", response);
        dispatch({
          type: type.CREATE_GAME_USER_SUCCESS,
          payload: {
            ...response?.data,
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Game status created",
            severity: "success",
            id: `CREATE_GAME_USER_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log("CREATE_GAME_USER_FAILURE", error);
      dispatch({
        type: type.CREATE_GAME_USER_FAILURE,
        payload: [error.message],
      });

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: "Failed to update game status",
          severity: "error",
          id: `CREATE_GAME_USER_FAILURE_${new Date().getTime()}`,
        },
      });
    }
  };

  postData();
};

export const updateGameUserAction = (gameUserData, id) => (dispatch) => {
  console.log("preparing to create game user", gameUserData);

  dispatch({
    type: type.UPDATE_GAME_USER_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;

      // Update the game user

      response = await updateGameUser(
        pluck(gameUserData, [
          "game_id",
          "user_id",
          "status",
          "is_favourite",
          "status_date",
        ]),
        id,
        ["user"],
      );

      if (response.errors.length > 0) {
        console.log("UPDATE_GAME_USER_FAILURE", response.errors);
        dispatch({
          type: type.UPDATE_GAME_USER_FAILURE,
          payload: response.errors,
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Failed to update game status",
            severity: "error",
            id: `UPDATE_GAME_USER_FAILURE_${new Date().getTime()}`,
          },
        });
      } else {
        console.log("UPDATE_GAME_USER_SUCCESS", response);
        dispatch({
          type: type.UPDATE_GAME_USER_SUCCESS,
          payload: {
            ...response?.data,
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Game status updated",
            severity: "success",
            id: `UPDATE_GAME_USER_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log("UPDATE_GAME_USER_FAILURE", error);
      dispatch({
        type: type.UPDATE_GAME_USER_FAILURE,
        payload: [error.message],
      });

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: "Failed to update game status",
          severity: "error",
          id: `UPDATE_GAME_USER_FAILURE_${new Date().getTime()}`,
        },
      });
    }
  };

  postData();
};

export const createOrUpdateGameUserAction = (gameUser) => (dispatch) => {
  if (!gameUser?.id) {
    console.log("creating new game user via api", gameUser);
    dispatch(createGameUserAction(gameUser));
  } else {
    console.log("editing existing game user via api", gameUser);
    dispatch(updateGameUserAction(gameUser, gameUser?.id));
  }
};

export const setGameStatusAction = (gameUser, status) => (dispatch) => {
  if (!gameUser?.id) {
    console.log("creating new game user via api");
    dispatch(createGameUserAction({ ...gameUser, status }));
  } else {
    console.log("editing existing game user via api");
    dispatch(updateGameUserAction({ ...gameUser, status }, gameUser?.id));
  }
};
