import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findByKey, move } from "../../helpers/helpers";

// TODO split out the draggable stuff
// TODO also split draggable item from container
export const DraggableItem = ({ style }) => {
  const styles = {
    container: {
      ...style,
      ...style?.container,
    },
    draggable: {
      backgroundColor: "#4AAE9B",
      fontWeight: "normal",
      marginBottom: "10px",
      marginTop: "10px",
      padding: "10px",
      cursor: "grab",
    },
    dropzone: {
      backgroundColor: "#6DB65B",
      flexBasis: "100%",
      flexGrow: "1",
      padding: "10px",
      minHeight: "300px",
    },
  };

  const defaultItems = [
    { id: "1", value: "List name 1" },
    { id: "2", value: "List name 2" },
    { id: "3", value: "List name 3" },
    { id: "4", value: "List name 4" },
    { id: "5", value: "List name 5" },
  ];
  const [items, setItems] = useState(defaultItems);

  const containerRef = useRef();
  const draggingObject = useRef(null);
  const itemRef = useRef(defaultItems);

  const startIndex = useRef(null);
  const endIndex = useRef(null);

  const getDragAfterElement = (container, y) => {
    const draggableElements = [...container?.querySelectorAll(".draggable:not(.dragging)")];

    return draggableElements.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return { offset, element: child };
        }
        return closest;
      },
      { offset: Number.NEGATIVE_INFINITY },
    ).element;
  };

  useEffect(() => {
    const container = containerRef?.current;
    if (!containerRef?.current) {
      return;
    }

    const draggables = containerRef?.current?.querySelectorAll(".draggable") || [];

    draggables.forEach((draggable) => {
      draggable.addEventListener("dragstart", () => {
        console.log("dragging", draggable);
        draggingObject.current = draggable;
        draggable.classList.add("dragging");
        // eslint-disable-next-line no-param-reassign
        draggable.style.backgroundColor = "yellow";
      });

      draggable.addEventListener("dragend", () => {
        console.log("dropping", draggable);
        draggingObject.current = null;
        draggable.classList.remove("dragging");
        // eslint-disable-next-line no-param-reassign
        draggable.style.backgroundColor = "#4AAE9B";

        const newItems = move(itemRef.current, startIndex.current, endIndex.current);

        itemRef.current = newItems;
        setItems(newItems);

        // containerRef?.current.insertBefore(draggable, afterElement);
      });
    });

    containerRef?.current.addEventListener("dragover", (e) => {
      e.preventDefault();
      const afterElement = getDragAfterElement(containerRef?.current, e.clientY);
      const draggable = container.querySelector(".dragging");

      if (!draggable) {
        return;
      }

      const item = findByKey(itemRef.current, draggable.id);
      const beforeItem = findByKey(itemRef.current, afterElement?.id);

      const itemPosition = itemRef.current.findIndex((searchItem) => searchItem?.id === item?.id);
      let destinationPosition = itemRef.current.findIndex(
        (searchItem) => searchItem?.id === beforeItem?.id,
      );

      // If swapping second to first, it can confuse it
      if (destinationPosition < 0) {
        destinationPosition = 0;
      }

      // But if we dragged to nothing, e.g. the end, place it at the end
      if (!beforeItem) {
        destinationPosition = -1;
      }

      startIndex.current = itemPosition;
      endIndex.current = destinationPosition;

      if (!afterElement) {
        containerRef?.current.appendChild(draggable);
      } else {
        containerRef?.current.insertBefore(draggable, afterElement);
      }
    });
  }, []);

  return (
    <div style={styles.container}>
      <div ref={containerRef} style={styles.dropzone} className="dropzone">
        {items?.map((item) => (
          <div
            key={item?.id}
            id={item?.id}
            style={styles.draggable}
            className="draggable"
            draggable="true"
          >
            {item?.value}
          </div>
        ))}
      </div>
    </div>
  );
};

DraggableItem.defaultProps = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DraggableItem);
