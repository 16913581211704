// App only
export const SHOW_MANAGE_LISTS_MODAL = "SHOW_MANAGE_LISTS_MODAL";
export const HIDE_MANAGE_LISTS_MODAL = "HIDE_MANAGE_LISTS_MODAL";

// API RELATED
export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const CLEAR_LOGIN_USER = "CLEAR_LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const SIGNUP_USER_PENDING = "SIGNUP_USER_PENDING";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";
export const CLEAR_SIGNUP_USER = "CLEAR_SIGNUP_USER";

export const FETCH_SELF_PENDING = "FETCH_SELF_PENDING";
export const FETCH_SELF_SUCCESS = "FETCH_SELF_SUCCESS";
export const FETCH_SELF_FAILURE = "FETCH_SELF_FAILURE";

// Games
export const FETCH_GAME_PENDING = "FETCH_GAME_PENDING";
export const FETCH_GAME_SUCCESS = "FETCH_GAME_SUCCESS";
export const FETCH_GAME_FAILURE = "FETCH_GAME_FAILURE";

export const FETCH_GAMES_PENDING = "FETCH_GAMES_PENDING";
export const FETCH_GAMES_SUCCESS = "FETCH_GAMES_SUCCESS";
export const FETCH_GAMES_FAILURE = "FETCH_GAMES_FAILURE";

export const CREATE_GAME_PENDING = "CREATE_GAME_PENDING";
export const CREATE_GAME_SUCCESS = "CREATE_GAME_SUCCESS";
export const CREATE_GAME_FAILURE = "CREATE_GAME_FAILURE";

export const UPDATE_GAME_PENDING = "UPDATE_GAME_PENDING";
export const UPDATE_GAME_SUCCESS = "UPDATE_GAME_SUCCESS";
export const UPDATE_GAME_FAILURE = "UPDATE_GAME_FAILURE";

export const FETCH_GAME_USERS_PENDING = "FETCH_GAME_USERS_PENDING";
export const FETCH_GAME_USERS_SUCCESS = "FETCH_GAME_USERS_SUCCESS";
export const FETCH_GAME_USERS_FAILURE = "FETCH_GAME_USERS_FAILURE";

export const CREATE_GAME_USER_PENDING = "CREATE_GAME_USER_PENDING";
export const CREATE_GAME_USER_SUCCESS = "CREATE_GAME_USER_SUCCESS";
export const CREATE_GAME_USER_FAILURE = "CREATE_GAME_USER_FAILURE";

export const UPDATE_GAME_USER_PENDING = "UPDATE_GAME_USER_PENDING";
export const UPDATE_GAME_USER_SUCCESS = "UPDATE_GAME_USER_SUCCESS";
export const UPDATE_GAME_USER_FAILURE = "UPDATE_GAME_USER_FAILURE";

export const FETCH_FRIENDS_PENDING = "FETCH_FRIENDS_PENDING";
export const FETCH_FRIENDS_SUCCESS = "FETCH_FRIENDS_SUCCESS";
export const FETCH_FRIENDS_FAILURE = "FETCH_FRIENDS_FAILURE";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";

// Reviews
export const FETCH_REVIEWS_PENDING = "FETCH_REVIEWS_PENDING";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_FAILURE = "FETCH_REVIEWS_FAILURE";

export const CREATE_REVIEW_PENDING = "CREATE_REVIEW_PENDING";
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS";
export const CREATE_REVIEW_FAILURE = "CREATE_REVIEW_FAILURE";

export const UPDATE_REVIEW_PENDING = "UPDATE_REVIEW_PENDING";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";
export const UPDATE_REVIEW_FAILURE = "UPDATE_REVIEW_FAILURE";

// Users
export const FETCH_USER_PENDING = "FETCH_USER_PENDING";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const FETCH_USERS_PENDING = "FETCH_USERS_PENDING";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

// Game Lists
export const FETCH_GAME_LISTS_PENDING = "FETCH_GAME_LISTS_PENDING";
export const FETCH_GAME_LISTS_SUCCESS = "FETCH_GAME_LISTS_SUCCESS";
export const FETCH_GAME_LISTS_FAILURE = "FETCH_GAME_LISTS_FAILURE";

export const CREATE_GAME_LIST_PENDING = "CREATE_GAME_LIST_PENDING";
export const CREATE_GAME_LIST_SUCCESS = "CREATE_GAME_LIST_SUCCESS";
export const CREATE_GAME_LIST_FAILURE = "CREATE_GAME_LIST_FAILURE";

export const UPDATE_GAME_LIST_PENDING = "UPDATE_GAME_LIST_PENDING";
export const UPDATE_GAME_LIST_SUCCESS = "UPDATE_GAME_LIST_SUCCESS";
export const UPDATE_GAME_LIST_FAILURE = "UPDATE_GAME_LIST_FAILURE";

export const BATCH_UPDATE_GAME_LISTS_PENDING = "BATCH_UPDATE_GAME_LISTS_PENDING";
export const BATCH_UPDATE_GAME_LISTS_SUCCESS = "BATCH_UPDATE_GAME_LISTS_SUCCESS";
export const BATCH_UPDATE_GAME_LISTS_FAILURE = "BATCH_UPDATE_GAME_LISTS_FAILURE";

// Game List Games
export const FETCH_GAME_LIST_GAMES_PENDING = "FETCH_GAME_LIST_GAMES_PENDING";
export const FETCH_GAME_LIST_GAMES_SUCCESS = "FETCH_GAME_LIST_GAMES_SUCCESS";
export const FETCH_GAME_LIST_GAMES_FAILURE = "FETCH_GAME_LIST_GAMES_FAILURE";

export const CREATE_GAME_LIST_GAME_PENDING = "CREATE_GAME_LIST_GAME_PENDING";
export const CREATE_GAME_LIST_GAME_SUCCESS = "CREATE_GAME_LIST_GAME_SUCCESS";
export const CREATE_GAME_LIST_GAME_FAILURE = "CREATE_GAME_LIST_GAME_FAILURE";

export const UPDATE_GAME_LIST_GAME_PENDING = "UPDATE_GAME_LIST_GAME_PENDING";
export const UPDATE_GAME_LIST_GAME_SUCCESS = "UPDATE_GAME_LIST_GAME_SUCCESS";
export const UPDATE_GAME_LIST_GAME_FAILURE = "UPDATE_GAME_LIST_GAME_FAILURE";

// Other

export const FETCH_USER_PROFILE_PENDING = "FETCH_USER_PROFILE_PENDING";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";
