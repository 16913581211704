import React, { useEffect, useRef } from "react";
import { TextField } from "../TextField/TextField";

export const TextArea = ({
  style,
  label,
  disabled,
  value,
  type,
  loading,
  placeholder,
  onChange,
  onBlur,
  onEnter,
  autofocus,
}) => {
  const ref = useRef(null);
  const handleOnChange = (event) => {
    // Can't change if not editable
    if (disabled) {
      return;
    }

    const newValue = event.target.value;

    onChange(newValue);
  };

  // Auto focus
  useEffect(() => {
    // The timeout is because inputs revealed on click (e.g. in modals) have
    // their inputs stolen by the click
    if (ref.current && autofocus) {
      setTimeout(() => {
        console.log("focusing");
        ref.current.focus();
      }, 50);
    }
  }, []);

  const styles = {
    container: {
      ...style,
      position: "relative",
      width: "100%",
      backgroundColor: "#FFFFFF",
      // zIndex: 5,
      ...style?.container,
    },
    label: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#333333",
      marginBottom: "8px",
      ...style?.label,
    },
    textArea: {
      backgroundColor: "#FFFFFF",
      ...style?.textArea,
    },
    disabled: {
      backgroundColor: "#F0F0F0",
      ...style?.disabled,
    },
  };

  return (
    <div style={styles.container}>
      {label && <div style={styles.label}>{label}</div>}
      <textarea
        rows={5}
        onBlur={onBlur}
        autoCorrect="off"
        spellCheck="off"
        autoCapitalize="words"
        className="text-input"
        value={value || ""}
        placeholder={placeholder}
        onChange={handleOnChange}
        tabIndex={0}
        ref={ref}
        disabled={disabled}
        style={{ ...styles.textArea, ...(disabled && styles.disabled) }}
      />
    </div>
  );
};

TextArea.defaultProps = {
  type: "text",
  loading: false,
  placeholder: "Start typing...",
  onChange: () => {},
  onBlur: () => {},
  onEnter: () => {},
  autofocus: false,
};
