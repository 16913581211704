import React from "react";
import { theme } from "../../theme";
import { ReactComponent as DownArrow } from "../../assets/down_arrow_filled.svg";

export const DropdownButton = ({
  text,
  style,
  onClick,
  disabled,
  loading,
  children,
}) => {
  const styles = {
    container: {
      ...theme.h3,
      cursor: "pointer",
      userSelect: "none",
      transitionDuration: "0.1s",
      color: "#333333",
      ...style?.container,
    },
    button: {
      display: "flex",
      gap: "4px",
      ...style?.button,
    },
    text: {
      ...style?.text,
    },
    dropdownImageContainer: {
      marginTop: "-2px",
      ...style?.dropdownImageContainer,
    },
    dropdownImage: {
      width: "14px",
      height: "8px",
      ...style?.dropdownImage,
    },
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    onClick(event);
  };

  return (
    <div className="dropdown-button-container" style={styles.container}>
      <div style={styles.button} onClick={handleClick}>
        <div style={styles.text}>
          {text} {children}
        </div>
        <div style={styles.dropdownImageContainer}>
          <DownArrow style={styles.dropdownImage} />
        </div>
      </div>
    </div>
  );
};
