import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import Header from "../components/Header/Header";
import ManageListsModal from "../components/Modal/ManageListsModal";
import Toast from "../components/Toast/Toast";
import { fetchGameListGamesAction } from "../data/actions/gameListGamesAction";
import { fetchGameListsAction } from "../data/actions/gameListsAction";
import { fetchSelfAction, fetchUsersAction } from "../data/actions/usersAction";
import { filterByProperty, flattenToProperty } from "../helpers/helpers";

const AppLayout = ({
  children,
  login,
  fetchSelf,
  user,
  modals,
  fetchGameLists,
  gameLists,
  fetchGameListGames,
  fetchUsers,
}) => {
  const navigate = useNavigate();

  const fetchOrLogout = () => {
    if (login?.token) {
      fetchSelf();
    } else {
      console.log("no token");
      navigate("/login");
    }
  };

  // Always fetch self - this is used in the header
  // Check user is logged in. If not logged in, redirect to login screen
  useEffect(() => {
    fetchOrLogout();
  }, [login.token]);

  // useInterval(() => {
  //   console.log("30 MINUTE INTERVAL REACHED");
  //   fetchOrLogout();
  // }, 1000 * 30 * 60); // Every 30 minutes

  // Fetch required data
  useEffect(() => {
    if (user?.data?.id) {
      fetchGameLists([], [["created_by_id", "=", user?.data?.id]]);
      fetchUsers(); // To get list of friends
    }
  }, [user?.data?.id]);

  // Refetch game lists if flagged to
  useEffect(() => {
    if (gameLists?.shouldFetch) {
      fetchGameLists([], [["created_by_id", "=", user?.data?.id]]);
    }
  }, [gameLists?.shouldFetch]);

  // If we have a game list, fetch the gameListGames for them
  useEffect(() => {
    if (gameLists?.data?.length > 0) {
      const gameListIds = flattenToProperty(
        filterByProperty(gameLists?.data, user?.data?.id, "created_by_id"),
      );

      fetchGameListGames([], [["game_list_id", "in", gameListIds]]);
    }
  }, [gameLists?.data]);

  // Wait for user
  if (user?.loading) {
    return <>Loading...</>;
  }

  if (!user?.success) {
    return <></>;
  }

  return (
    <div className="app-layout" style={{ minHeight: "100vh" }}>
      <Header />
      <div
        style={{
          paddingTop: "84px",
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "auto",
          marginRight: "auto",
          width: "calc(100% - 48px)",
          maxWidth: "1400px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            minHeight: "calc(100vh - 184px)",
          }}
        >
          {children}
          <Toast />
        </div>
      </div>
      <ManageListsModal isOpen={modals.showManageListsModal} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
  user: state.user,
  modals: state.modals,
  gameLists: state.gameLists,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSelf: fetchSelfAction,
      fetchGameLists: fetchGameListsAction,
      fetchGameListGames: fetchGameListGamesAction,
      fetchUsers: fetchUsersAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
