import { pluck } from "../../helpers/helpers";
import { createReview, fetchReviews, updateReview } from "../apiClient";
import * as type from "./types";

export const fetchReviewsAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_REVIEWS_PENDING,
    });

    const getData = async () => {
      let response = null;

      try {
        response = await fetchReviews(includes, filters);

        if (response.errors.length > 0) {
          console.log("FETCH_REVIEWS_FAILURE", response.errors);
          dispatch({
            type: type.FETCH_REVIEWS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log("FETCH_REVIEWS_SUCCESS", response);
          dispatch({
            type: type.FETCH_REVIEWS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log("FETCH_REVIEWS_FAILURE", error);
        dispatch({
          type: type.FETCH_REVIEWS_FAILURE,
          payload: [error.message],
        });
      }
    };

    getData();
  };

export const createReviewAction = (reviewData) => (dispatch) => {
  console.log("preparing to create review", reviewData);

  dispatch({
    type: type.CREATE_REVIEW_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;

      // Create the review
      response = await createReview(
        pluck(reviewData, [
          "game_user_id",
          "status",
          "rating",
          "review_text",
          "reviewed_at",
        ]),
        ["user"]
      );

      if (response.errors.length > 0) {
        console.log("CREATE_REVIEW_FAILURE", response.errors);
        dispatch({
          type: type.CREATE_REVIEW_FAILURE,
          payload: response.errors,
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Failed to update review",
            severity: "error",
            id: `CREATE_REVIEW_FAILURE_${new Date().getTime()}`,
          },
        });
      } else {
        console.log("CREATE_REVIEW_SUCCESS", response);
        dispatch({
          type: type.CREATE_REVIEW_SUCCESS,
          payload: {
            ...response?.data,
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Review created",
            severity: "success",
            id: `CREATE_REVIEW_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log("CREATE_REVIEW_FAILURE", error);
      dispatch({
        type: type.CREATE_REVIEW_FAILURE,
        payload: [error.message],
      });

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: "Failed to update review",
          severity: "error",
          id: `CREATE_REVIEW_FAILURE_${new Date().getTime()}`,
        },
      });
    }
  };

  postData();
};

export const updateReviewAction = (reviewData, id) => (dispatch) => {
  console.log("preparing to create review", reviewData);

  dispatch({
    type: type.UPDATE_REVIEW_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;

      // Update the review
      response = await updateReview(
        pluck(reviewData, ["status", "rating", "review_text", "reviewed_at"]),
        ["user"]
      );

      if (response.errors.length > 0) {
        console.log("UPDATE_REVIEW_FAILURE", response.errors);
        dispatch({
          type: type.UPDATE_REVIEW_FAILURE,
          payload: response.errors,
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Failed to update review",
            severity: "error",
            id: `UPDATE_REVIEW_FAILURE_${new Date().getTime()}`,
          },
        });
      } else {
        console.log("UPDATE_REVIEW_SUCCESS", response);
        dispatch({
          type: type.UPDATE_REVIEW_SUCCESS,
          payload: {
            ...response?.data,
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: "Review updated",
            severity: "success",
            id: `UPDATE_REVIEW_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log("UPDATE_REVIEW_FAILURE", error);
      dispatch({
        type: type.UPDATE_REVIEW_FAILURE,
        payload: [error.message],
      });

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: "Failed to update review",
          severity: "error",
          id: `UPDATE_REVIEW_FAILURE_${new Date().getTime()}`,
        },
      });
    }
  };

  postData();
};

export const createOrUpdateReviewAction = (review) => (dispatch) => {
  if (!review?.id) {
    dispatch(createReviewAction(review));
  } else {
    dispatch(updateReviewAction(review, review?.id));
  }
};
