import React from "react";

export const LoginLayout = ({ children }) => (
  <div className="app-layout" style={{ minHeight: "100vh" }}>
    <div
      style={{
        paddingTop: "100px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "calc(100% - 48px)",
        // minWidth: "calc(100% - 48px)",
        maxWidth: "1448px",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  </div>
);
