import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { createGameListGameAction } from "../../data/actions/gameListGamesAction";
import { reviewMap } from "../../data/lists";
import { findByKey, stringToHTML } from "../../helpers/helpers";
import { OutlineButton } from "../Button/OutlineButton";
import { reviewCardStyles } from "./ReviewCardStyles";
import ReviewStatus from "./ReviewStatus";

const ReviewCard = ({ game, style, gameUsers, reviews, gameUser, user, users, blurred }) => {
  const styles = reviewCardStyles({ style, game });
  const navigate = useNavigate();
  const [isBlurred, setIsBlurred] = useState(blurred);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setIsBlurred(blurred);
  }, [blurred]);

  return (
    <div style={styles.container}>
      <div
        key={gameUser?.user_id + gameUser?.game_id}
        style={{
          ...styles.reviewContainer,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={styles.playerName} onClick={() => navigate(`/user/${gameUser?.user_id}`)}>
            {findByKey(users?.data, gameUser?.user_id)?.username}
          </div>
          <div style={styles.container}>
            <ReviewStatus game={game} gameUser={gameUser} />
          </div>
        </div>
        {gameUser?.review ? (
          <div>
            <div style={styles.rating}>{reviewMap?.[gameUser?.review?.rating]}</div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  ...styles.reviewText,
                  filter: isBlurred ? "blur(6px)" : "none",
                }}
              >
                {gameUser?.review?.review_text}
              </div>

              {isBlurred && (
                <div
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {hovered && (
                    <div
                      style={{
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                        top: "40%",
                        left: "50%",
                      }}
                    >
                      <OutlineButton
                        text="View Spoiler"
                        variant="square"
                        onClick={() => setIsBlurred(false)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

ReviewCard.defaultProps = {};

ReviewCard.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users,
  gameUsers: state.gameUsers,
  gameLists: state.gameLists,
  reviews: state.reviews,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createGameListGame: createGameListGameAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCard);
