import * as type from "../actions/types";

const initialState = {
  messages: [],
  message: "",
  id: "", // otherwise messages won't repeat
  severity: "", // success, error
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_NOTIFICATION: {
      const { message, severity, id } = action.payload;

      return {
        ...state,
        messages: [...state.messages, message],
        id,
        message,
        severity,
      };
    }

    // case type.REMOVE_MESSAGE: {
    //   const { id } = action.payload;
    //   const removeMessage = [
    //     ...state.messages.filter((foundMessage) => foundMessage.id !== id),
    //   ];
    //
    //   return {
    //     ...state,
    //     messages: removeMessage,
    //   };
    // }
    //
    // case type.CLEAR_MESSAGES:
    //   return {
    //     ...state,
    //     messages: [],
    //   };

    default:
      return state;
  }
};
