import React from "react";
import { Checkbox } from "./Checkbox";

export const CheckboxGroup = ({
  label,
  items = [],
  setItems = () => {},
  style,
  disabled,
  loading,
}) => {
  const toggleItem = (itemId) => {
    const newItems = items.map((item) => {
      if (item?.id === itemId) {
        const newItem = { ...item };
        newItem.value = !newItem?.value;
        return newItem;
      }

      return item;
    });

    return setItems(newItems);
  };

  const styles = {
    container: {
      ...style?.container,
    },
    label: {
      marginBottom: "8px",
      ...style?.text,
    },
    checkboxGroup: {
      display: "flex",
      flexDirection: "column",
      gap: "3px",
      ...style?.checkboxGroup,
    },
    item: {
      ...style?.item,
    },
  };

  return (
    <div className="checkbox-group-container" style={styles.container}>
      <div style={styles.label}>{label}</div>
      <div style={styles.checkboxGroup}>
        {items.map((item) => (
          <div key={item.id} style={styles.item}>
            <Checkbox
              label={item?.label}
              value={item?.value}
              onClick={() => toggleItem(item?.id)}
              disabled={disabled}
              loading={loading}
              style={{
                label: {
                  color: "#000000",
                },
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
