import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GameList from "../components/GameList/GameList";
import { fetchGameListsAction } from "../data/actions/gameListsAction";
import { fetchGamesAction } from "../data/actions/gamesAction";
import { fetchGameUsersAction } from "../data/actions/gameUsersAction";
import { fetchReviewsAction } from "../data/actions/reviewsAction";
import { fetchUsersAction } from "../data/actions/usersAction";
import { filterByProperty, findByKey, sortObjectList } from "../helpers/helpers";

const Home = ({
  fetchGameUsers,
  fetchUsers,
  gameUsers,
  fetchGames,
  games,
  user,
  users,
  fetchGameLists,
  fetchReviews,
}) => {
  const [processedGames, setProcessedGames] = useState([]);

  // console.log("rendering home");

  // Set the page name TODO move this to route
  useEffect(() => {
    document.title = "Games Collection";
  }, []);

  // Fetch all games with user data
  useEffect(() => {
    // TODO can optimise by converting into separate requests (e.g. to avoid redundant user data)
    console.log("fetching games");
    fetchGames(["gameUserIds", "artwork"]);
    fetchGameUsers();
    fetchUsers();
    // fetchGameLists([], ["user_id", "=", user?.data?.id]);
    fetchReviews();
  }, []);

  useEffect(() => {
    console.log("Games or game user changed, filtering games", games, gameUsers);

    const newGames = games?.data?.map((game) => {
      const gameUsersForGame = filterByProperty(gameUsers?.data, game?.id, "game_id");

      return {
        ...game,
        gameUsers: gameUsersForGame,
      };
    });

    // TODO sort by game user last updated

    let sortedGameIds = [];
    sortObjectList(gameUsers?.data, "updated_at", true).forEach((gameUser) => {
      if (!sortedGameIds?.includes(gameUser?.game_id)) {
        sortedGameIds = [...sortedGameIds, gameUser?.game_id];
      }
    });

    const sortedGames = sortedGameIds?.map((gameId) => findByKey(newGames, gameId));
    const finalGames = [
      ...sortedGames,
      ...newGames?.filter((game) => !sortedGameIds?.includes(game?.id)),
    ];

    // TODO more complex sorting, such as prioritising in progress games

    setProcessedGames(finalGames);
  }, [games?.data, gameUsers?.data]);

  return (
    <div className="dashboard-container" style={{ paddingBottom: "500px" }}>
      <GameList
        heading="Master list"
        games={processedGames || []}
        loading={games?.fetching || gameUsers?.fetching || users?.fetching}
        style={{ marginTop: "32px" }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  gameUsers: state.gameUsers,
  games: state.games,
  users: state.users,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGameUsers: fetchGameUsersAction,
      fetchGames: fetchGamesAction,
      fetchGameLists: fetchGameListsAction,
      fetchUsers: fetchUsersAction,
      fetchReviews: fetchReviewsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
