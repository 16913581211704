import React from "react";
import { theme } from "../../theme";

// TODO this should set all fields inactive that are under the modal (e.g. can't tab to them)
export const PrimaryButton = ({
  text,
  style,
  onClick = () => {},
  disabled,
  loading = false,
  children,
  variant,
}) => {
  const getVariantStyles = () => {
    if (variant === "square") {
      return {
        borderRadius: "4px",
        padding: "9.5px 16px",
      };
    }

    return {};
  };

  const styles = {
    container: {
      ...style,
      cursor: "pointer",
      userSelect: "none",
      transitionDuration: "0.1s",
      padding: "16px 32px",
      backgroundColor: "#333333",
      borderRadius: "50px",
      color: "#FFFFFF",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      textAlign: "center",
      whiteSpace: "nowrap",
      ...getVariantStyles(),
      ...style?.container,
    },
    button: {
      ...style?.button,
    },
    text: {
      ...style?.text,
    },
    disabled: {
      backgroundColor: "#555555",
      cursor: "default",
    },
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    onClick(event);
  };

  return (
    <div
      className="primary-button-container"
      style={{
        ...styles.container,
        ...(loading && { backgroundColor: "#555555", cursor: "default" }),
        ...(disabled && styles.disabled),
      }}
      onClick={handleClick}
    >
      <div
        style={{
          ...styles.button,
        }}
      >
        <div style={styles.text}>
          {loading ? (
            "Loading..."
          ) : (
            <div>
              {text} {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
