import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import GameList from "../components/GameList/GameList";
import CurrentlyPlaying from "../components/Profile/CurrentlyPlaying";
import PlayerStats from "../components/Profile/PlayerStats";
import UserHeader from "../components/Profile/UserHeader";
import { fetchGameListGamesAction } from "../data/actions/gameListGamesAction";
import { fetchGameListsAction } from "../data/actions/gameListsAction";
import { fetchGamesAction } from "../data/actions/gamesAction";
import { fetchGameUsersAction } from "../data/actions/gameUsersAction";
import { fetchUserAction, fetchUserProfileAction } from "../data/actions/usersAction";
import { filterByProperty, findByKey, flattenToProperty } from "../helpers/helpers";

const Profile = ({
  games,
  gameUsers,
  users,
  fetchUser,
  fetchGameUsers,
  fetchGameLists,
  fetchGameListGames,
  fetchGames,
  gameLists,
}) => {
  const { userId } = useParams();
  // console.log("rendering profile", games, gameUsers, users);

  const [user, setUser] = useState(null);
  const [gamesWithData, setGamesWithData] = useState([]);

  // Set the page name TODO move this to route
  useEffect(() => {
    document.title = "Profile | Games Collection";
  }, []);

  useEffect(() => {
    // Fetch The user we are viewing the profile for
    fetchUser(userId);
    fetchGameUsers([], [["user_id", "=", userId]]);
    fetchGameLists([], [["user_id", "=", userId]]);
    // fetchGameUsers(["game", "artwork"], [["user_id", "=", userId]]);
  }, [userId]);

  // Store the fetched user
  useEffect(() => {
    if (!users?.fetching && users?.success) {
      console.log("about to find user", users?.data, userId);
      const userFromResponse = findByKey(users?.data, userId);
      setUser(userFromResponse);
    }
  }, [users?.success, users?.fetching]);

  // If we have a game list, fetch the gameListGames for them
  useEffect(() => {
    console.log("game list changed, fetching game list games", gameLists);

    const gameListIds = flattenToProperty(
      filterByProperty(gameLists?.data, userId, "created_by_id"),
    );

    fetchGameListGames([], [["game_list_id", "in", gameListIds]]);
  }, [gameLists?.data]);

  useEffect(() => {
    console.log("Game user changed, fetching games", gameUsers, userId);
    const newGameIds = gameUsers?.data
      ?.filter((gameUser) => gameUser?.user_id === userId)
      ?.map((gameUser) => gameUser?.game_id);
    fetchGames(["artwork"], [["id", "in", newGameIds]]);
  }, [gameUsers?.data, userId]);

  useEffect(() => {
    console.log("Games or game user changed, filtering games", games, gameUsers);

    // Todo filter game users to just this game user
    const newGames = gameUsers?.data
      ?.filter((gameUser) => gameUser?.user_id === userId)
      ?.map((gameUser) => ({
        ...findByKey(games?.data, gameUser?.game_id),
        gameUsers: [gameUser],
      }));

    setGamesWithData(newGames);
  }, [games?.data, gameUsers?.data]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      marginBottom: "500px",
    },
  };

  if (users?.errors?.length > 0) {
    return <div>An error occurred</div>;
  }

  if (users?.fetching || !users?.success || !user) {
    return <div>Loading...</div>;
  }

  const thisGameUsers = gameUsers?.data?.filter((gameUser) => gameUser?.user_id === userId);

  const gamesInProgress = gamesWithData?.filter((game) => game?.status === "in_progress");

  return (
    <div style={styles.container}>
      <UserHeader style={{ marginTop: "32px" }} user={user} />
      <PlayerStats user={user} gameUsers={thisGameUsers} />
      {gamesInProgress?.length > 0 && <CurrentlyPlaying user={user} games={gamesInProgress} />}
      <GameList
        user={user}
        games={gamesWithData}
        lists={gameLists?.data?.filter((list) => list?.created_by_id === userId)}
        activeUserAtTop={false}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
  gameUsers: state.gameUsers,
  games: state.games,
  gameLists: state.gameLists,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserProfile: fetchUserProfileAction,
      fetchGameUsers: fetchGameUsersAction,
      fetchGames: fetchGamesAction,
      fetchGameLists: fetchGameListsAction,
      fetchGameListGames: fetchGameListGamesAction,
      fetchUser: fetchUserAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
