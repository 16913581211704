import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { theme } from "../theme";

const NotFound = () => {
  const navigate = useNavigate();
  const styles = {
    container: {},
    login: {
      backgroundColor: "#FFFFFF",
      padding: "24px",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "450px",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    errorMessage: {
      color: "red",
      marginBottom: "16px",
    },
  };
  return (
    <div onClick={() => navigate("/")} style={theme.clickable}>
      <div style={styles.login}>
        <div style={{ marginBottom: "8px", ...theme.h2 }}>Not found</div>
        <div
          style={{
            marginBottom: "24px",
            fontSize: "16px",
          }}
        >
          That sucks. Click here to return home.
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
