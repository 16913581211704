import { combineReducers } from "redux";
import gameListsReducer from "./gameListsReducer";
import gameListGamesReducer from "./gameListGamesReducer";
import gamesReducer from "./gamesReducer";
import gameUsersReducer from "./gameUsersReducer";
import loginReducer from "./loginReducer";
import modalsReducer from "./modalsReducer";
import reviewsReducer from "./reviewsReducer";
import signupReducer from "./signupReducer";
import toastReducer from "./toastReducer";
import userReducer from "./userReducer";
import usersReducer from "./usersReducer";

const appReducer = combineReducers({
  games: gamesReducer,
  gameLists: gameListsReducer,
  gameListGames: gameListGamesReducer,
  gameUsers: gameUsersReducer,
  login: loginReducer,
  reviews: reviewsReducer,
  signup: signupReducer,
  toasts: toastReducer,
  user: userReducer,
  users: usersReducer,
  modals: modalsReducer,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
