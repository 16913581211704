import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { OutlineButton } from "../components/Button/OutlineButton";
import { TextButton } from "../components/Button/TextButton";
import AddGameModal from "../components/Modal/AddGameModal";
import EditGameModal from "../components/Modal/EditGameModal";
import ReviewCard from "../components/ReviewCard/ReviewCard";
import { fetchGameListGamesAction } from "../data/actions/gameListGamesAction";
import { fetchGameListsAction } from "../data/actions/gameListsAction";
import { fetchGameAction, fetchGamesAction } from "../data/actions/gamesAction";
import { fetchGameUsersAction } from "../data/actions/gameUsersAction";
import { fetchReviewsAction } from "../data/actions/reviewsAction";
import { fetchUserAction, fetchUserProfileAction } from "../data/actions/usersAction";
import { completedStatuses } from "../data/lists";
import { filterByProperty, findByKey, flattenToProperty } from "../helpers/helpers";
import { theme } from "../theme";

const GameProfile = ({
  games,
  gameUsers,
  user,
  fetchGameUsers,
  fetchGame,
  fetchReviews,
  reviews,
}) => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [game, setGame] = useState(null);
  const [showEditGameModal, setShowEditGameModal] = useState(false);

  useEffect(() => {
    // Fetch the game we are viewing the profile for
    fetchGame(gameId, ["gameUserIds", "artwork"]);
    fetchGameUsers(["reviews"], [["game_id", "=", gameId]]);
  }, [gameId]);

  // Store the fetched game
  useEffect(() => {
    if (!games?.fetching && games?.success) {
      console.log("about to find game", games?.data, gameId);
      const gameFromResponse = findByKey(games?.data, gameId);
      setGame(gameFromResponse);
    }
  }, [games?.success, games?.fetching]);

  // Set the title to the game's name
  useEffect(() => {
    document.title = `${game?.name} | Games Collection`;
  }, [game]);

  // Fetch reviews for each gameUser
  useEffect(() => {
    if (gameUsers?.data?.length > 0) {
      fetchReviews([], [["game_user_id", "in", flattenToProperty(gameUsers?.data)]]);
    }
  }, [gameUsers?.data]);

  // If game was edited, and image changed, refresh the image
  // TODO optimise by fetching images separately in the first place
  useEffect(() => {
    if (game?.artwork_id !== game?.artwork?.data?.id) {
      fetchGame(gameId, ["gameUserIds", "artwork"]);
    }
  }, [game]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      marginTop: "32px",
      marginBottom: "500px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      ...theme.h1,
    },
    gameSummary: {
      display: "flex",
      flexWrap: "wrap",
      borderRadius: "4px",
      overflow: "hidden",
      backgroundColor: "white",
      ...theme.cardShadow,
    },
    keyArt: {
      width: "40%",
      overflow: "hidden",
    },
    gameImage: {
      width: "100%",
      minWidth: "350px",
      maxWidth: "600px",
      maxHeight: "400px",
      height: "100%",
      objectFit: "cover",
    },
    gameDescription: {
      flex: 1,
      minWidth: "350px",
      padding: "24px 40px",
      backgroundColor: "white",
      whiteSpace: "pre-line",
    },
    gameUsers: {
      // marginTop: "16px",
      display: "flex",
      alignItems: "flex-start",
      // flexBasis: "50%",
      flexWrap: "wrap",
      // flex: 1,
      gap: "16px",
    },
  };

  // Get all the "statuses" and reviews we want to show in separate cards
  const getAllStatuses = () => {
    // TODO this could be replaced with a sort
    let selfGameUser = gameUsers?.data?.find(
      (gameUserToFind) =>
        gameUserToFind?.user_id === user?.data?.id && gameUserToFind?.game_id === game?.id,
    );

    // If this user hasn't played the game yet, add placeholder data
    if (!selfGameUser) {
      selfGameUser = {
        game_id: game?.id,
        has_completed: false,
        has_reviewed: false,
        // id: "",
        is_favourite: false,
        reviews: [],
        // status: "in_progress",
        user_id: user?.data?.id,
        user: {
          data: user?.data,
        },
      };
    }

    let statuses = [];

    const gameUsersForGame = filterByProperty(gameUsers?.data, gameId, "game_id");

    gameUsersForGame?.forEach((gameUser) => {
      // Self will be added to the top at the end
      if (gameUser === selfGameUser?.id) {
        return;
      }

      const reviewsForUser = filterByProperty(reviews?.data, gameUser?.id, "game_user_id");

      // If user has no reviews, just show them as a status
      if (!reviewsForUser) {
        statuses = [...statuses, gameUser];
      } else {
        // If user has reviews, each review should be its own thing
        reviewsForUser?.forEach((review) => {
          statuses = [
            ...statuses,
            {
              ...gameUser,
              review,
            },
          ];
        });
      }
    });

    // TODO need to break down self user ito multiple reviews (potentially)

    return [selfGameUser, ...statuses];
  };

  const statuses = getAllStatuses();

  if (games?.errors?.length > 0) {
    return <div>An error occurred</div>;
  }

  if (games?.fetching || !games?.success || !game) {
    // return <div>Loading...</div>;
  }

  // console.log("GAME IS", game, gameUsers);

  const selfGameUser = gameUsers?.data?.find(
    (gameUserToFind) =>
      gameUserToFind?.user_id === user?.data?.id && gameUserToFind?.game_id === game?.id,
  );
  const userHasFinished = completedStatuses?.includes(selfGameUser?.status);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>
          {game?.name} {game?.year ? `(${game?.year})` : ""}
        </div>
        <OutlineButton variant="square" onClick={() => setShowEditGameModal(true)}>
          Edit game details
        </OutlineButton>
      </div>
      <div style={styles.gameSummary}>
        <div style={styles.keyArt}>
          <img
            style={styles.gameImage}
            src={game?.artwork?.data?.url}
            alt={`${game?.name} key art`}
          />
        </div>
        <div style={styles.gameDescription}>
          {game?.description || (
            <div style={{ textAlign: "center", marginTop: "80px" }}>
              <div style={theme.h1}>No description yet</div>
              <div style={{ display: "inline-flex", fontWeight: "500", gap: "4px" }}>
                <TextButton
                  style={{
                    container: {
                      padding: 0,
                      color: "#2626CD",
                    },
                  }}
                  onClick={() => setShowEditGameModal(true)}
                >
                  Click here
                </TextButton>
                to add a description
              </div>
            </div>
          )}
        </div>
      </div>

      <div style={styles.gameUsers}>
        {statuses?.map((gameUser, index) => (
          <ReviewCard
            game={game}
            gameUser={gameUser}
            key={gameUser?.id + gameUser?.review?.id}
            blurred={!userHasFinished}
          />
        ))}
      </div>

      <EditGameModal
        showCloseButton
        title="Edit game"
        isOpen={showEditGameModal}
        onClose={() => setShowEditGameModal(false)}
        game={game}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users,
  gameUsers: state.gameUsers,
  games: state.games,
  gameLists: state.gameLists,
  reviews: state.reviews,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserProfile: fetchUserProfileAction,
      fetchGameUsers: fetchGameUsersAction,
      fetchGames: fetchGamesAction,
      fetchGame: fetchGameAction,
      fetchGameLists: fetchGameListsAction,
      fetchGameListGames: fetchGameListGamesAction,
      fetchUser: fetchUserAction,
      fetchReviews: fetchReviewsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GameProfile);
