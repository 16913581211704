import * as type from "./types";

export const FETCH_USER = "FETCH_USER";

export const handleResponse = (response, action, dispatch) => {
  console.log("r", response, action);
  if (response?.errors?.length > 0) {
    console.log(`${action}_FAILURE`, response.errors);
    dispatch({
      type: `${action}_FAILURE`,
      payload: response.errors,
    });
  } else {
    console.log(`${action}_SUCCESS`, response);
    dispatch({
      type: `${action}_SUCCESS`,
      payload: response?.data?.data,
    });
  }
};

export const beginAction = (action, dispatch) => {
  dispatch({
    type: `${action}_PENDING`,
  });
};

export const handleFailure = (error, action, dispatch) => {
  dispatch({
    type: `${action}_FAILURE`,
    payload: [error.message],
  });
};

export const showMessageOnSuccess = (response, message, action, dispatch) => {
  if (response?.errors?.length === 0) {
    dispatch({
      type: type.CREATE_NOTIFICATION,
      payload: {
        message,
        severity: "success",
        id: `${action}_${new Date().getTime()}`,
      },
    });
  }
};
