import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDefaultGameStatusValues, getDefaultRatingValues } from "../../data/lists";
import {
  filterByProperty,
  findByKey,
  flatten,
  pluck,
  sortObjectList,
  sortValues,
  stringInString,
} from "../../helpers/helpers";
import { theme } from "../../theme";
import { TextButton } from "../Button/TextButton";
import GameCard from "../GameCard/GameCard";
import { ListFilters } from "../ListFilters/ListFilters";
import AddGameModal from "../Modal/AddGameModal";
import { SortBar } from "../SortBar/SortBar";
import { TabBar } from "../TabBar/TabBar";

const GameList = ({
  heading,
  style,
  loading,
  games,
  user,
  activeUserAtTop,
  lists,
  gameListGames,
  gameUsers,
  activeUser,
  gameLists,
  reviews,
  gamesByTab,
  users,
}) => {
  // Just a fun thing to show a question mark 50% of the time
  const showQuestionMark = Math.random() >= 0.5;

  const styles = {
    container: {
      ...style,
      // marginTop: "24px",
      display: "flex",
      gap: "16px",
      ...style?.container,
    },
    heading: {
      ...style?.heading,
    },
    filters: {
      ...style?.filters,
    },
    list: {
      flex: 1,
      ...style?.list,
    },
    listWrapper: {
      marginTop: "16px",
      display: "flex",
      alignItems: "flex-start",
      // flexBasis: "50%",
      flexWrap: "wrap",
      // flex: 1,
      gap: "16px",
      ...style?.listWrapper,
    },
  };

  // TODO order games by progress then latest review date

  const [gamesForTab, setGamesForTab] = useState(games);
  const [selectedTab, setSelectedTab] = useState("all_games");
  const [filteredGames, setFilteredGames] = useState(games);
  const [showAddGameModal, setShowAddGameModal] = useState(false);

  // Filters

  const getFollowedPlayers = () =>
    // TODO filter users to just followed users (and self?)
    users?.data?.map((followedPlayer) => ({
      id: followedPlayer?.id,
      label: followedPlayer?.username,
      value: false,
    }));

  const getListCheckboxes = () => {
    const gameListsForUser = filterByProperty(
      gameLists?.data,
      user ? user?.id : activeUser?.data?.id,
      "created_by_id",
    );

    return sortObjectList(gameListsForUser, "index")?.map((gameList) => ({
      id: gameList?.id,
      label: gameList?.name,
      value: false,
    }));
  };

  // Filters
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(getDefaultGameStatusValues());
  const [followedPlayers, setFollowedPlayers] = useState(getFollowedPlayers());
  const [ratings, setRatings] = useState(getDefaultRatingValues());
  const [listFilters, setListFilters] = useState(getListCheckboxes());
  const [dateMin, setDateMin] = useState(null);
  const [dateMax, setDateMax] = useState(null);

  const [sortMode, setSortMode] = useState("default");

  const usingTabs = lists?.length > 0;
  const tabs = [
    { label: "All games", value: "all_games" },
    ...lists?.map((list) => ({
      label: list?.name,
      value: list?.id,
    })),
  ];

  // Rank a game for sorting based on the default algorithm.
  // Returns an object with a rank number, and a date.
  // If two games have the same rank, the date sorting puts them in the right spot
  // TODO this should probably be in the reducer
  const rankGame = (game) => {
    const currentTime = moment();

    // TODO if filtering out certain people, ignore that person in the sort algorithm

    const gameUsersForGame = gameUsers?.data?.filter((gameUser) => gameUser?.game_id === game?.id);

    const badStatuses = ["added_to_list"];
    const statusesToNotShowAtTop = ["added_to_list", "not_interested"];

    // Rank 1 check
    // Show any game updated by any user in the past week, except added to list
    const recentGameUsers = gameUsersForGame?.filter(
      (gameUser) =>
        gameUser?.status !== null &&
        !statusesToNotShowAtTop.includes(gameUser?.status) &&
        currentTime.diff(gameUser?.updated_at, "days") <= 7,
    );

    if (recentGameUsers?.length > 0) {
      return { rank: 1, time: sortObjectList(recentGameUsers, "updated_at", true)[0]?.updated_at };
    }

    // Rank 2 check
    // Show in-progress games (any user), but only those updated within the last 3 months
    const inProgressUsers = gameUsersForGame?.filter(
      (gameUser) =>
        gameUser?.status === "in_progress" && currentTime.diff(gameUser?.updated_at, "months") <= 3,
    );

    if (inProgressUsers?.length > 0) {
      return { rank: 2, time: sortObjectList(inProgressUsers, "updated_at", true)[0]?.updated_at };
    }

    // Rank 3 check
    // Show the rest of the games except added-to-list
    const normalGameUsers = gameUsersForGame?.filter(
      (gameUser) => !badStatuses.includes(gameUser?.status),
    );

    if (normalGameUsers?.length > 0) {
      return { rank: 3, time: sortObjectList(normalGameUsers, "updated_at", true)[0]?.updated_at };
    }

    // Rank 4
    // Show added to list
    const listGameUsers = gameUsersForGame?.filter(
      (gameUser) => gameUser?.status === "added_to_list",
    );

    if (listGameUsers?.length > 0) {
      return { rank: 4, time: sortObjectList(listGameUsers, "updated_at", true)[0]?.updated_at };
    }

    // Rank 5
    // Show "no status", but ordered by game's release date desc
    if (gameUsersForGame?.length === 0) {
      return { rank: 5, time: game?.year };
    }

    // // Rank 6
    // // Show "not interested"
    // const notInterestedUsers = gameUsersForGame?.filter(
    //   (gameUser) => gameUser?.status === "added_to_list",
    // );

    // if (notInterestedUsers?.length > 0) {
    //   return {
    //     rank: 6,
    //     time: sortObjectList(notInterestedUsers, "updated_at", true)[0]?.updated_at,
    //   };
    // }

    // Rank 99
    // This shouldn't be possible
    return {
      rank: 99,
      time: game?.year,
    };
  };

  const sortGameList = () => {
    // TODO sort by specific release date of game

    // If sorted by game release
    if (sortMode === "release_date") {
      return sortObjectList(games, "year", true);
    }

    // If sorted by status date
    if (sortMode === "status_date") {
      const gamesWithMostRecentStatus = games?.map((game) => {
        const gameUsersForGame = gameUsers?.data?.filter(
          (gameUser) => gameUser?.game_id === game?.id,
        );

        const gameUsersWithStatus = gameUsersForGame?.filter(
          (gameUser) => gameUser?.status !== null,
        );

        return {
          ...game,
          sortDate: sortObjectList(gameUsersWithStatus, "status_date", true)[0]?.status_date,
        };
      });

      return sortObjectList(gamesWithMostRecentStatus, "sortDate", true);
    }

    // If sorted by last updated (anyone)
    // TODO double check whether I should use updated_at or status_date
    if (sortMode === "last_updated_anyone") {
      const gamesWithMostRecentStatus = games?.map((game) => {
        const gameUsersForGame = gameUsers?.data?.filter(
          (gameUser) => gameUser?.game_id === game?.id,
        );

        const gameUsersWithStatus = gameUsersForGame?.filter(
          (gameUser) => gameUser?.status !== null,
        );

        return {
          ...game,
          sortDate: sortObjectList(gameUsersWithStatus, "updated_at", true)[0]?.updated_at,
        };
      });

      return sortObjectList(gamesWithMostRecentStatus, "sortDate", true);
    }

    // If sorted by last updated (me)
    // TODO double check whether I should use updated_at or status_date
    if (sortMode === "last_updated_self") {
      const gamesWithMostRecentStatus = games?.map((game) => {
        console.log("game users", game, gameUsers, activeUser);
        const gameUser = game?.gameUsers?.filter(
          (gameUserToFilter) => gameUserToFilter?.user_id === activeUser?.data?.id,
        )?.[0];

        return {
          ...game,
          sortDate: gameUser?.updated_at,
        };
      });

      return sortObjectList(gamesWithMostRecentStatus, "sortDate", true);
    }

    // Otherwise default...
    // Default sort algorithm:
    // Order games by status date updated
    // Show any game updated by any user in the past week, except added to list
    // Show in-progress games (any user), but only those updated within the last 3 months
    // Show the rest of the games except added-to-list
    // Show added to list
    // Show "no status", but ordered by game's release date desc

    const gamesWithSortRanks = games?.map((game) => ({
      ...game,
      rank: rankGame(game),
    }));

    console.log("gamesWithSortRanks", gamesWithSortRanks);

    const allSortedGames = sortObjectList(gamesWithSortRanks, "time", true)?.sort((a, b) => {
      if (a?.rank?.rank > b?.rank?.rank) {
        return 1;
      }

      if (b?.rank?.rank > a?.rank?.rank) {
        return -1;
      }

      // Equal rank
      return sortValues(a?.rank?.time, b?.rank?.time, true);
    });

    return allSortedGames;
  };

  // Set games in list by tab
  useEffect(() => {
    if (!usingTabs || selectedTab === "all_games") {
      // TODO sort by last GAMEUSER
      const newFilteredGames = sortGameList();
      // const newFilteredGames = sortObjectList(games, "updated_at", true);
      setGamesForTab(newFilteredGames);
      return;
    }

    const selectedList = findByKey(lists, selectedTab);
    const gamesForList = filterByProperty(gameListGames?.data, selectedList?.id, "game_list_id");
    const justGames = gamesForList?.map((gameForList) => findByKey(games, gameForList?.game_id));

    setGamesForTab(justGames || []);
  }, [games, selectedTab, gameListGames?.data, sortMode]);

  // Filter games
  useEffect(() => {
    const statusesToFilter = flatten(
      statusFilter?.filter((status) => status?.value === true),
      "id",
    );

    const ratingsToFilter = flatten(
      ratings?.filter((rating) => rating?.value === true),
      "id",
    );

    const listsToFilter = flatten(
      listFilters?.filter((listFilter) => listFilter?.value === true),
      "id",
    );

    const playersToFilter = flatten(
      followedPlayers?.filter((player) => player?.value === true),
      "id",
    );

    const newFilteredGames = gamesForTab
      ?.filter((game) => {
        if (nameFilter) {
          return stringInString(game?.name, nameFilter);
        }

        return true;
      })
      ?.filter((game) => {
        // Filter status
        // TODO if filtering players, only check against those players' statuses
        if (statusesToFilter?.length !== 0) {
          const gameUsersForGame = gameUsers?.data?.filter(
            (gameUser) => gameUser?.game_id === game?.id,
          );

          // Special case to check for games that haven't been played or added to a list yet
          if (statusesToFilter?.includes("no_status")) {
            if (gameUsersForGame?.filter((gameUser) => gameUser?.status !== null)?.length === 0) {
              return true;
            }
          }

          const gameUsersWithStatus = gameUsersForGame?.filter((gameUser) =>
            statusesToFilter?.includes(gameUser?.status),
          );

          return gameUsersWithStatus?.length > 0;
        }

        return true;
      })
      ?.filter((game) => {
        if (playersToFilter?.length !== 0) {
          console.log("ss", gameUsers);
          const gameUsersForGame = gameUsers?.data?.filter(
            (gameUser) =>
              gameUser?.game_id === game?.id && playersToFilter?.includes(gameUser?.user_id),
          );

          return gameUsersForGame?.length > 0;
        }

        return true;
      })
      ?.filter((game) => {
        // Filter Rating
        // TODO if filtering players, only check against those players' ratings
        if (ratingsToFilter?.length !== 0) {
          const gameUsersForGame = gameUsers?.data?.filter(
            (gameUser) => gameUser?.game_id === game?.id,
          );

          const gameUsersWithReviews = gameUsersForGame?.filter((gameUserForGame) => {
            const reviewsForGameUser = filterByProperty(
              reviews?.data,
              gameUserForGame?.id,
              "game_user_id",
            );

            const latestReviewForGameUser = sortObjectList(
              reviewsForGameUser,
              "reviewed_at",
              true,
            )?.[0];

            return ratingsToFilter?.includes(latestReviewForGameUser?.rating);
          });

          return gameUsersWithReviews?.length > 0;
        }

        return true;
      })
      ?.filter((game) => {
        // Filter Updated

        if (dateMin && dateMax) {
          const min = moment(dateMin).subtract("1", "day"); // add or subtract to make inclusive
          const max = moment(dateMax).add("1", "day");
          const gameUsersForGame = gameUsers?.data?.filter(
            (gameUser) => gameUser?.game_id === game?.id,
          );

          return (
            gameUsersForGame.filter((gameUser) => {
              const statusDate = moment(gameUser?.status_date);
              return statusDate.isBetween(min, max);
            }).length > 0
          );
        }

        return true;
      })
      ?.filter((game) => {
        // Filter Lists
        if (listsToFilter?.length !== 0) {
          const listsGameIsOn = gameListGames?.data?.filter(
            (gameListGame) =>
              gameListGame?.game_id === game?.id &&
              listsToFilter.includes(gameListGame?.game_list_id),
          );

          return listsGameIsOn?.length > 0;
        }

        return true;
      });

    setFilteredGames(newFilteredGames);
  }, [
    gamesForTab,
    nameFilter,
    statusFilter,
    followedPlayers,
    ratings,
    listFilters,
    user,
    activeUser,
    gameUsers,
    dateMin,
    dateMax,
  ]);

  // Get game lists to show
  useEffect(() => {
    setListFilters(getListCheckboxes());
  }, [gameLists?.data, user?.id, activeUser?.data?.id]);

  // Get followed players
  useEffect(() => {
    setFollowedPlayers(getFollowedPlayers());
  }, [users?.data, user?.id, activeUser?.data?.id]);

  return (
    <div style={styles.container}>
      <div style={styles.filters}>
        <ListFilters
          searchInput={nameFilter}
          setSearchInput={setNameFilter}
          gameStatuses={statusFilter}
          setGameStatuses={setStatusFilter}
          followedPlayers={followedPlayers}
          setFollowedPlayers={setFollowedPlayers}
          dateMin={dateMin}
          dateMax={dateMax}
          setDateMin={setDateMin}
          setDateMax={setDateMax}
          ratings={ratings}
          setRatings={setRatings}
          lists={listFilters}
          setLists={setListFilters}
        />
      </div>
      <div style={styles.list}>
        <div style={{ ...theme.h1, ...styles.heading }}>{heading}</div>
        {usingTabs && <TabBar tabs={tabs} selectedTab={selectedTab} onSelect={setSelectedTab} />}
        {/* {usingTabs && ( */}
        {/*  <div style={{ borderBottom: "1px solid #D4D4D4" }}> */}
        {/*    {lists?.map((list) => ( */}
        {/*      <div>{list?.name}</div> */}
        {/*    ))} */}
        {/*  </div> */}
        {/* )} */}
        <div style={{ marginTop: "24px" }}>
          <SortBar
            setShowAddGameModal={setShowAddGameModal}
            onChange={setSortMode}
            sortMode={sortMode}
          />
        </div>
        {loading ? (
          <div style={{ marginTop: "16px" }}>Loading</div>
        ) : filteredGames?.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              maxWidth: "500px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "128px",
            }}
          >
            <div style={theme.h1}>Nothing to show</div>
            {!user && (
              <div style={{ ...theme.featured, marginTop: "8px" }}>
                <div>Can&apos;t find the the game you&apos;re looking for?</div>
                <div style={{ display: "flex", justifyContent: "center", gap: "4px" }}>
                  <div>Why not</div>
                  <TextButton
                    onClick={() => setShowAddGameModal(true)}
                    style={{ container: { padding: 0, color: "#2626CD" } }}
                  >
                    add it now
                    {showQuestionMark && (
                      <span style={{ fontSize: "30px", position: "absolute" }}>?</span>
                    )}
                  </TextButton>
                </div>
              </div>
            )}
            <div style={{ ...theme.featured, marginTop: "16px" }}>
              {user?.username &&
                `Either you've gone overboard with the filters or ${user?.username} 
                has some work to do`}
            </div>
          </div>
        ) : (
          <div style={styles.listWrapper}>
            {filteredGames.map((game) => (
              <GameCard
                key={game?.id}
                game={game}
                activeUserAtTop={activeUserAtTop}
                style={{ flex: 1 }}
              />
            ))}
          </div>
        )}
      </div>
      <AddGameModal
        showCloseButton
        title="Add new game"
        isOpen={showAddGameModal}
        onClose={() => setShowAddGameModal(false)}
      />
    </div>
  );
};

GameList.defaultProps = {
  games: [],
  lists: [],
  activeUserAtTop: true,
};

GameList.propTypes = {
  activeUserAtTop: PropTypes.bool,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  lists: PropTypes.arrayOf(PropTypes.shape({})),
  activeUser: PropTypes.shape({}).isRequired,
  // gameListGames: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  activeUser: state.user,
  gameUsers: state.gameUsers,
  gameLists: state.gameLists,
  reviews: state.reviews,
  gameListGames: state.gameListGames,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GameList);
