import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { createGameListGameAction } from "../../data/actions/gameListGamesAction";
import { createGameListGame } from "../../data/apiClient";
import { findByKey, sortObjectList } from "../../helpers/helpers";
import { theme } from "../../theme";
import { ActionMenu } from "../ActionMenu/ActionMenu";
import { HeartButton } from "../Button/HeartButton";
import GameStatus from "./GameStatus";

const GameCard = ({
  game,
  style,
  onClick,
  disabled,
  loading,
  user,
  users,
  gameUsers,
  activeUserAtTop,
  gameLists,
  createGameListGame,
}) => {
  const styles = {
    container: {
      ...style,
      position: "relative",
      maxWidth: "350px",
      minWidth: "350px",
      ...style?.container,
    },
    gameCard: {
      // display: "flex",
      background: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      minHeight: "198px",
      // overflow: "hidden",
      ...style?.gameCard,
    },
    imageContainer: {
      ...theme.clickable,
      position: "relative",
      // width: "186px",
      width: "100%",
      height: "198px",
      overflow: "hidden",
      backgroundColor: "black",
      backgroundImage: `url('${game?.artwork?.data?.url}')`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: "4px 4px 0 0",
      ...style?.imageContainer,
    },
    image: {
      justifyContent: "center",
      // width: "186px",
      // maxHeight: "198px",
      width: "100%",
      minWidth: "100%",
      minHeight: "198px",
      objectFit: "fill",
      backgroundColor: "black",
      objectPosition: "50% 50%",
      WebkitUserDrag: "none",
      userSelect: "none",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      ...style?.image,
    },
    heartButton: {
      position: "absolute",
      right: "16px",
      top: "16px",
      ...style?.heartButton,
    },
    updateText: {
      position: "absolute",
      cursor: "pointer",
      userSelect: "none",
      display: "inline-flex",
      gap: "10px",
      color: "white",
      bottom: 0,
      left: 0,
      width: "186px",
      marginBottom: "10px",
      // left: "50%",
      justifyContent: "center",
      transitionDuration: "0.1s",
      opacity: 0,
      ...style?.updateText,
    },
    details: {
      position: "relative",
      ...style?.details,
    },
    name: {
      ...theme.h2,
      ...style?.name,
    },
    reviews: {
      // display: "flex",
      width: "100%",
      // gap: "16px",
      ...style?.reviews,
    },
    reviewContainer: {
      display: "flex",
      gap: "8px",
      height: "31px",
      padding: "16px",
      alignItems: "center",
      justifyContent: "space-between",
      // flex: 1,
      ...style?.reviewContainer,
    },
    playerName: {
      ...theme.h4,
      flex: 1,
      ...theme.clickable,
      ...style?.playerName,
    },
    rating: {
      ...style?.rating,
    },
    description: {
      ...style?.description,
    },
  };

  const navigate = useNavigate();

  const [showListMenu, setShowListMenu] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    // TODO navigate to that game's page

    onClick(event);
  };

  // TODO hovering over the image should make the update text visible

  const getPlayers = () => {
    // Get all game users for a game
    let gameUsersForGame = game?.gameUsers || [];

    if (!activeUserAtTop) {
      return gameUsersForGame;
    }

    // TODO this could be replaced with a sort
    let selfGameUser = gameUsers?.data?.find(
      (gameUserToFind) =>
        gameUserToFind?.user_id === user?.data?.id && gameUserToFind?.game_id === game?.id,
    );

    // If this user hasn't played the game yet, add placeholder data
    if (!selfGameUser) {
      selfGameUser = {
        game_id: game?.id,
        has_completed: false,
        has_reviewed: false,
        // id: "",
        is_favourite: false,
        reviews: [],
        // status: "in_progress",
        user_id: user?.data?.id,
        user: {
          data: user?.data,
        },
      };
    }

    // Put this user at the top of the list
    gameUsersForGame = [
      selfGameUser,
      ...gameUsers?.data?.filter(
        (userToFilter) =>
          userToFilter?.user_id !== user?.data?.id && userToFilter?.game_id === game?.id,
      ),
    ];

    return gameUsersForGame;

    // If game user has reviewed, get the related review
  };

  const addToList = (item) => {
    console.log("item");

    createGameListGame({
      game_list_id: item?.value,
      game_id: game?.id,
    });
  };

  const gameUsersForGame = getPlayers();

  const getUsersGameLists = () => {
    const gameAlreadyInList = (list) => {
      // TODO get games in list
    };

    sortObjectList(
      gameLists?.data?.filter((gameList) => gameList?.user_id === user?.id),
      "index",
    )?.map((gameList) => ({
      label: gameList?.name,
      value: gameList?.id,
    }));
  };

  return (
    <div style={styles.container}>
      <div style={styles.gameCard}>
        <div style={styles.imageContainer} onClick={() => navigate(`/game/${game?.id}`)}>
          {/* <img */}
          {/*  style={styles.image} */}
          {/*  src={game?.artwork?.data?.url} */}
          {/*  alt={`${game?.name} art`} */}
          {/* /> */}
          <div style={styles.heartButton}>
            <HeartButton onClick={() => setShowListMenu(true)} />
          </div>

          {/* <div className="visible-on-hover" style={styles.updateText}> */}
          {/*  <EditIcon /> */}
          {/*  <div>Update</div> */}
          {/* </div> */}
        </div>
        <div style={styles.details}>
          {/* <div style={styles.name}> */}
          {/*  {game?.name + (game?.year && ` (${game?.year})`)} */}
          {/* </div> */}
          <div style={styles.reviews}>
            {gameUsersForGame.map((gameUser, i) => (
              <div
                key={gameUser?.user_id + gameUser?.game_id}
                style={{
                  ...styles.reviewContainer,
                  ...(i === 0
                    ? ""
                    : {
                        borderTop: "1px solid #D4D4D4",
                      }),
                }}
              >
                <div
                  style={styles.playerName}
                  onClick={() => navigate(`/user/${gameUser?.user_id}`)}
                >
                  {findByKey(users?.data, gameUser?.user_id)?.username}
                </div>
                <GameStatus game={game} gameUser={gameUser} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div style={{ position: "absolute", textAlign: "left", top: "50px", right: "50px" }}>
        <ActionMenu
          isOpen={showListMenu}
          onClose={() => setShowListMenu(false)}
          onSelect={addToList}
          style={{ textAlign: "left" }}
          items={gameLists?.data?.map((gameList) => ({
            label: gameList?.name,
            value: gameList?.id,
          }))}
        />
      </div>
    </div>
  );
};

GameCard.defaultProps = {
  activeUserAtTop: true,
};

GameCard.propTypes = {
  activeUserAtTop: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users,
  gameUsers: state.gameUsers,
  gameLists: state.gameLists,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createGameListGame: createGameListGameAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GameCard);
