import React from "react";

export const OutlineButton = ({ text, style, onClick, disabled, loading, children, variant }) => {
  const getVariantStyles = () => {
    if (variant === "square") {
      return {
        borderRadius: "4px",
        padding: "9.5px 16px",
        border: "1px solid #333333",
      };
    }

    return {};
  };

  const styles = {
    container: {
      ...style,
      cursor: "pointer",
      userSelect: "none",
      transitionDuration: "0.1s",
      padding: "14px 30px",
      backgroundColor: "white",
      border: "2px solid #333333",
      borderRadius: "50px",
      color: "#333333",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      textAlign: "center",
      whiteSpace: "nowrap",
      width: "fit-content",
      // height: "100%",
      // width: "100%",
      ...getVariantStyles(),
      ...style?.container,
    },
    button: {
      ...style?.button,
    },
    buttonContent: {
      ...style?.buttonContent,
    },
    disabled: {
      backgroundColor: "#DDDDDD",
      cursor: "default",
    },
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    event.stopPropagation();
    onClick(event);
  };

  return (
    <div
      className={`outline-button-container ${disabled && "outline-button-container-disabled"}`}
      style={{
        ...styles.container,
        ...(loading && { backgroundColor: "#555555", cursor: "default" }),
        ...(disabled && styles.disabled),
      }}
      onClick={handleClick}
    >
      <div style={styles.button}>
        <div style={styles.buttonContent}>
          {text} {children}
        </div>
      </div>
    </div>
  );
};
