import React, { useState } from "react";
import { theme } from "../../theme";

export const TabBar = ({
  style,
  disabled,
  loading,
  tabs,
  selectedTab,
  onSelect = () => {},
}) => {
  const styles = {
    container: {
      ...style,
      borderBottom: "1px solid #D4D4D4",
      display: "flex",
      gap: "16px",
      ...theme.featured,
      ...style?.container,
    },
    tabItemContainer: {
      position: "relative",
      ...style?.tabItemContainer,
    },
    tabItem: {
      padding: "8px",
      ...theme.clickable,
      ...style?.tabItem,
    },
    selectionMarker: {
      backgroundColor: "#333333",
      height: "3px",
      borderRadius: "3px 3px 0  0",
      position: "absolute",
      bottom: "-2px",
      width: "100%",
      ...style?.selectionMarker,
    },
  };

  return (
    <div style={styles.container}>
      {tabs?.map((tab) => (
        <div
          style={styles.tabItemContainer}
          onClick={() => onSelect(tab?.value)}
        >
          <div style={styles.tabItem}>{tab?.label}</div>
          {selectedTab === tab?.value && <div style={styles.selectionMarker} />}
        </div>
      ))}
    </div>
  );
};
