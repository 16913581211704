import * as type from "./types";

export const showManageListsModalAction = () => (dispatch) => {
  dispatch({
    type: type.SHOW_MANAGE_LISTS_MODAL,
  });
};

export const hideManageListsModalAction = () => (dispatch) => {
  dispatch({
    type: type.HIDE_MANAGE_LISTS_MODAL,
  });
};
