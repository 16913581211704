import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showManageListsModalAction } from "../../data/actions/modalAction";
import { theme } from "../../theme";
import { OutlineButton } from "../Button/OutlineButton";
import { PrimaryButton } from "../Button/PrimaryButton";

const UserHeader = ({ user, activeUser, style, showManageListsModal }) => {
  const styles = {
    container: {
      ...style,
      height: "44px",
      width: "100%",
      alignItems: "center",
      ...style.container,
    },
  };

  const isSelf = user?.id === activeUser?.data?.id;
  const isFollowing = false;

  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          alignItems: "center",
        }}
      >
        <div style={{ ...theme.h2 }}>{user?.username}</div>
        {/* TODO different buttons depending on if it's you, and if you follow them */}
        {isSelf ? (
          <div style={{ display: "flex", gap: "8px" }}>
            <OutlineButton variant="square">Edit profile</OutlineButton>
            <OutlineButton variant="square" onClick={showManageListsModal}>
              Edit your lists
            </OutlineButton>
          </div>
        ) : isFollowing ? (
          <OutlineButton variant="square">Unfollow {user?.username}</OutlineButton>
        ) : (
          <PrimaryButton variant="square">Follow {user?.username}</PrimaryButton>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showManageListsModal: showManageListsModalAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
