import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { playedStatuses } from "../../data/lists";
import { pluralise } from "../../helpers/helpers";
import PlayStatCard from "./PlayStatCard";

const PlayerStats = ({ user, style, gameUsers }) => {
  const styles = {
    container: {
      ...style,
      display: "flex",
      gap: "16px",
      flexWrap: "wrap",
      ...style?.container,
    },
    heading: {
      ...style?.heading,
    },
  };

  const playedGames = gameUsers?.filter((gameUser) =>
    playedStatuses.includes(gameUser?.status),
  ).length;

  const completedGames = gameUsers?.filter(
    (gameUser) => gameUser?.status === "fully_completed",
  ).length;

  const listedGames = gameUsers?.filter(
    (gameUser) => gameUser?.status === "added_to_list",
  ).length;

  // TODO get followers
  const followers = 99;

  return (
    <div style={styles.container}>
      <PlayStatCard
        label="Played"
        value={playedGames}
        thing={pluralise("game", playedGames)}
      />
      <PlayStatCard
        label="100% completed"
        value={completedGames}
        thing={pluralise("game", completedGames)}
      />
      <PlayStatCard
        label="On list"
        value={listedGames}
        thing={pluralise("game", listedGames)}
      />
      <PlayStatCard
        label="Followed by"
        value={followers}
        thing={pluralise("player", followers)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  games: state.games,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStats);
