import { mergeArrays, replaceItem } from "../../helpers/helpers";
import * as type from "../actions/types";

const initialState = {
  errors: [],
  fetching: false,
  posting: false,
  success: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_GAME_USERS_PENDING:
      return {
        ...state,
        ...initialState,
        fetching: true,
        success: false,
        errors: [],
      };
    case type.FETCH_GAME_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true,
        errors: [],
        data: mergeArrays(state.data, action.payload?.data, "id", true),
      };
    case type.FETCH_GAME_USERS_FAILURE:
      return {
        ...state,
        fetching: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_GAME_USER_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.CREATE_GAME_USER_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.UPDATE_GAME_USER_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.UPDATE_GAME_USER_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_GAME_USER_SUCCESS:
      console.log("game user was created", action?.payload, state);
      const newItem = action?.payload?.data;

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, newItem, "id", true),
      };
    case type.UPDATE_GAME_USER_SUCCESS:
      console.log("game user was updated", action?.payload, state);
      const updatedItem = action?.payload?.data;

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, updatedItem, "id", true),
      };
    default:
      return state;
  }
};
