import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findByKey, move } from "../../helpers/helpers";
import { TextButton } from "../Button/TextButton";
import { TextField } from "../TextField/TextField";
import { ReactComponent as UpArrow } from "../../assets/icons/arrow_upward.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/arrow_downward.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as DragIndicator } from "../../assets/icons/drag_indicator.svg";

export const DraggableList = ({
  style,
  items,
  itemRef, // Since item state doesn't update in event listeners
  disabled,
  loading,
  onChange,
  onClose,
  placeholder,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);

  const onClick = (item) => {
    console.log("clicked", item);

    if (item?.onClick) {
      item?.onClick();
    }

    onClose();
  };

  const styles = {
    container: {
      ...style,
      position: "relative",
      ...style?.container,
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      ...style?.innerContainer,
    },
    item: {
      display: "flex",
      gap: "16px",
      maxHeight: "39px",
      ...style?.item,
    },
  };

  // Draggable stuff

  const containerRef = useRef();
  const draggingObject = useRef(null);

  const startIndex = useRef(null);
  const endIndex = useRef(null);

  const getDragAfterElement = (container, y) => {
    const draggableElements = [...container?.querySelectorAll(".draggable:not(.dragging)")];

    return draggableElements.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return { offset, element: child };
        }
        return closest;
      },
      { offset: Number.NEGATIVE_INFINITY },
    ).element;
  };

  useEffect(() => {
    const container = containerRef?.current;
    if (!containerRef?.current) {
      return;
    }

    const draggables = containerRef?.current?.querySelectorAll(".draggable") || [];

    draggables.forEach((draggable) => {
      draggable.addEventListener("dragstart", () => {
        // console.log("dragging", draggable);
        draggingObject.current = draggable;
        draggable.classList.add("dragging");
        // eslint-disable-next-line no-param-reassign
        draggable.style.backgroundColor = "#EEEEEE";
      });

      draggable.addEventListener("dragend", () => {
        console.log("dropping", draggable);
        draggingObject.current = null;
        draggable.classList.remove("dragging");
        // eslint-disable-next-line no-param-reassign
        draggable.style.backgroundColor = "white";

        const newItems = move(itemRef.current, startIndex.current, endIndex.current);

        // eslint-disable-next-line no-param-reassign
        itemRef.current = newItems;
        onChange(newItems);

        // containerRef?.current.insertBefore(draggable, afterElement);
      });
    });

    containerRef?.current.addEventListener("dragover", (e) => {
      e.preventDefault();
      const afterElement = getDragAfterElement(containerRef?.current, e.clientY);
      const draggable = container.querySelector(".dragging");

      if (!draggable) {
        return;
      }

      const item = findByKey(itemRef.current, draggable.id);
      const beforeItem = findByKey(itemRef.current, afterElement?.id);

      const itemPosition = itemRef.current.findIndex((searchItem) => searchItem?.id === item?.id);
      let destinationPosition = itemRef.current.findIndex(
        (searchItem) => searchItem?.id === beforeItem?.id,
      );

      // If swapping second to first, it can confuse it
      if (destinationPosition < 0) {
        destinationPosition = 0;
      }

      // But if we dragged to nothing, e.g. the end, place it at the end
      if (!beforeItem) {
        destinationPosition = -1;
      }

      startIndex.current = itemPosition;
      endIndex.current = destinationPosition;

      if (!afterElement) {
        containerRef?.current.appendChild(draggable);
      } else {
        containerRef?.current.insertBefore(draggable, afterElement);
      }
    });
  }, []);

  const deleteItem = (itemToDelete) => {
    const newItems = items?.filter((item) => item?.id !== itemToDelete);

    console.log("setting items", newItems);

    onChange(newItems);
  };

  const changeItemValue = (itemId, newValue) => {
    const updatedItems = items?.map((item) => {
      if (item?.id === itemId) {
        return {
          ...item,
          value: newValue,
        };
      }

      return item;
    });

    onChange(updatedItems);
  };

  return (
    <div style={styles.container} ref={wrapperRef}>
      <div style={styles.innerContainer} ref={containerRef} className="dropzone">
        {items.map((item) => (
          <div
            style={styles.item}
            key={item?.id}
            id={item?.id}
            className="draggable"
            draggable="true"
          >
            <div style={{ cursor: "grab" }}>
              <DragIndicator style={{ color: "#323232" }} />
            </div>
            <div
              draggable={false}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragEnd={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              style={{ width: "100%" }}
            >
              <TextField
                value={item?.value}
                onChange={(newValue) => changeItemValue(item?.id, newValue)}
                placeholder={placeholder}
              />
            </div>
            <TextButton
              style={{ container: { padding: "5px" } }}
              onClick={() => deleteItem(item?.id)}
            >
              <Delete style={{ color: "#CD2626" }} />
            </TextButton>
          </div>
        ))}
      </div>
    </div>
  );
};

DraggableList.defaultProps = {
  onSelect: () => {},
  onChange: () => {},
  items: [],
  onClose: () => {},
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DraggableList);
