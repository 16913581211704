import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { COLORS, theme } from "../../theme";

const PlayStatCard = ({ label, value, thing, style }) => {
  const styles = {
    container: {
      ...style,
      width: "306px",
      height: "92px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      padding: "16px",
      ...style?.container,
    },
    label: {
      ...theme.strong,
      color: COLORS.gray3,
      textTransform: "uppercase",
      // fontWeight: "700",
      fontSize: "14px",
    },
    valueWrapper: {
      display: "flex",
      gap: "8px",
      alignItems: "baseline",
    },
    value: {
      marginTop: "8px",
      fontSize: "48px",
      fontWeight: "bold",
      lineHeight: "73px",
      ...style?.value,
    },
    thing: {
      marginTop: "8px",
      fontSize: "14px",
      fontWeight: "700",
      ...style?.thing,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.label}>{label}</div>
      <div style={styles.valueWrapper}>
        <div style={styles.value}>{value}</div>
        <div style={styles.thing}>{thing}</div>
      </div>
    </div>
  );
};

PlayStatCard.defaultProps = {
  style: {},
  label: "",
  value: "",
  thing: "",
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlayStatCard);
