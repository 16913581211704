import moment from "moment";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { formatDate } from "../../helpers/helpers";
import { COLORS } from "../../theme";

export const RangeDatePicker = ({
  style,
  label,
  disabled,
  startDate,
  endDate,
  loading,
  placeholder,
  autofocus,
  onBlur,
  setStartDate,
  setEndDate,
}) => {
  const ref = useRef();
  const handleOnChange = (dates) => {
    console.log("dates", dates);

    // Can't change if not editable
    if (disabled) {
      return;
    }

    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // Auto focus
  useEffect(() => {
    // The timeout is because inputs revealed on click (e.g. in modals) have
    // their inputs stolen by the click
    if (ref.current && autofocus) {
      setTimeout(() => {
        console.log("focusing");
        ref.current.focus();
      }, 50);
    }
  }, []);

  const styles = {
    container: {
      ...style,
      position: "relative",
      width: "100%",
      ...style?.container,
    },
    label: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#333333",
      marginBottom: "8px",
      ...style?.label,
    },
    textContainer: {
      userSelect: "none",
      whiteSpace: "nowrap",
      padding: "13px 16px",
      fontSize: "14px",
      lineHeight: "14px",
      ...style?.text,
    },
    dropdown: {
      position: "relative",
      borderRadius: "4px",
      minHeight: "42px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      ...style?.dropdown,
    },
    dropdownClosed: {
      height: "40px",
      border: "1px solid #D4D4D4",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      ...style?.dropdownClosed,
    },
    icon: {
      width: "16px",
      height: "16px",
      marginLeft: "auto",
      padding: "13px 16px",
      color: COLORS.gray,
      ...style?.icon,
    },
  };

  const DatePickerInput = forwardRef(({ onClick }, ref) => (
    <div style={styles.dropdown} onClick={onClick} tabIndex={0}>
      <div style={styles.dropdownClosed}>
        <div style={styles.textContainer}>
          {startDate !== null && endDate !== null
            ? `${formatDate(startDate, "DD/MM/yyyy", "")} - ${formatDate(
                endDate,
                "DD/MM/yyyy",
                "",
              )}`
            : placeholder}
        </div>
        <CalendarIcon style={styles.icon} />
      </div>
    </div>
  ));

  return (
    <div style={styles.container}>
      {label && <div style={styles.label}>{label}</div>}
      <ReactDatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        ref={ref}
        className="date-input"
        placeholder={placeholder}
        tabIndex={0}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
        customInput={<DatePickerInput />}
        selected={startDate}
        onChange={handleOnChange}
      />
    </div>
  );
};

RangeDatePicker.defaultProps = {
  loading: false,
  placeholder: "Select dates...",
  onSelect: () => {},
  onBlur: () => {},
  setStartDate: () => {},
  setEndDate: () => {},
  autofocus: false,
  disabled: false,
};
