import React, { useState } from "react";
import { theme } from "../../theme";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { OutlineButton } from "../Button/OutlineButton";
import { PrimaryButton } from "../Button/PrimaryButton";

export const Modal = ({
  style,
  title,
  showCloseButton,
  showCancelButton,
  children,
  secondaryText,
  isOpen,
  submitText,
  loading,
  disabled,
  onClose,
  onSubmit,
}) => {
  const styles = {
    container: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      top: 0,
      left: 0,
      zIndex: 100,
      alignItems: "center",
      opacity: isOpen ? 1 : 0,
      visibility: isOpen ? "visible" : "hidden",
      transition: "visibility 0.2s, opacity 0.2s",
      ...style?.container,
    },
    modalBackground: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      backgroundColor: "#000000",
      opacity: "0.5",
      top: 0,
      left: 0,
      alignItems: "center",
      ...style?.modalBackground,
    },
    modalContainer: {
      maxWidth: "calc(100vw - 48px)",
      maxHeight: "calc(100vh - 48px)",
      position: "absolute",
      top: "50%",
      left: "50%",
      MsTransform: "translateY(-50%) translateX(-50%)",
      transform: "translateY(-50%) translateX(-50%)",
      zIndex: 10,
      ...style?.modalContainer,
    },
    modal: {
      position: "relative",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      width: "100%",
      minWidth: "600px",
      minHeight: "600px",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      overflowY: "auto",
      ...style?.modal,
    },
    modalHeader: {
      display: "flex",
      padding: "24px",
      borderBottom: "1px solid #D4D4D4",
      ...style?.modalHeader,
    },
    modalBody: {
      padding: "24px",
      paddingBottom: "124px", // To not overlap the modal footer
      ...style?.modalBody,
    },
    modalFooter: {
      display: "inline-flex",
      gap: "8px",
      padding: "24px",
      borderTop: "1px solid #D4D4D4",
      position: "absolute",
      width: "calc(100% - 48px)",
      bottom: 0,
      backgroundColor: "#FFFFFF",
      borderRadius: "0px 0px 8px 8px",
      ...style?.modalFooter,
    },
    title: {
      flex: 1,
      ...theme.h2,
      ...style?.title,
    },
    closeButton: {
      marginLeft: "auto",
      marginRight: "-6px", // To account for whitespace in svg
      cursor: "pointer",
      userSelect: "none",
      ...style?.closeButton,
    },
  };

  const closeModal = (event) => {
    event.stopPropagation();

    onClose();
  };

  // TODO prevent scrolling the background

  return (
    <div style={styles.container}>
      {isOpen && (
        <div>
          <div style={styles.modalBackground} onClick={closeModal} />
          <div style={styles.modalContainer}>
            <div style={styles.modal}>
              {(title || showCloseButton) && (
                <div style={styles.modalHeader}>
                  {title && <div style={styles.title}>{title}</div>}
                  {showCloseButton && (
                    <div style={styles.closeButton} onClick={closeModal}>
                      <CloseIcon style={{ width: "32px", height: "32px" }} />
                    </div>
                  )}
                </div>
              )}
              <div style={styles.modalBody}>{children}</div>
              {(submitText || showCancelButton) && (
                <div style={styles.modalFooter}>
                  {submitText && (
                    <PrimaryButton
                      variant="square"
                      onClick={onSubmit}
                      loading={loading}
                      disabled={disabled}
                      style={{ minWidth: "120px" }}
                    >
                      {submitText}
                    </PrimaryButton>
                  )}
                  {secondaryText && (
                    <OutlineButton
                      variant="square"
                      onClick={onSubmit}
                      loading={loading}
                      disabled={disabled}
                      style={{ minWidth: "120px" }}
                    >
                      {secondaryText}
                    </OutlineButton>
                  )}
                  {showCancelButton && (
                    <OutlineButton
                      variant="square"
                      onClick={closeModal}
                      disabled={disabled}
                      style={{ minWidth: "90px" }}
                    >
                      Cancel
                    </OutlineButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Modal.defaultProps = {
  isOpen: false,
  loading: false,
  disabled: false,
  onClose: () => {},
  onSubmit: () => {},
};
