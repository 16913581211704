import * as type from "../actions/types";

const initialState = {
  errors: [],
  loading: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SIGNUP_USER_PENDING:
      return {
        ...state,
        ...initialState,
        loading: true,
        success: false,
        errors: [],
      };
    case type.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        errors: [],
      };
    case type.SIGNUP_USER_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload,
      };
    case type.CLEAR_SIGNUP_USER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
