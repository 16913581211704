import React from "react";
import { getDefaultGameStatusValues, getDefaultRatingValues } from "../../data/lists";
import { TextButton } from "../Button/TextButton";
import { CheckboxGroup } from "../Checkbox/CheckboxGroup";
import { RangeDatePicker } from "../DatePicker/RangeDatePicker";
import { TextField } from "../TextField/TextField";

export const ListFilters = ({
  style,
  gameStatuses,
  setGameStatuses,
  followedPlayers,
  setFollowedPlayers,
  ratings,
  setRatings,
  lists,
  setLists,
  searchInput,
  setSearchInput,
  dateMin,
  dateMax,
  setDateMin,
  setDateMax,
}) => {
  const styles = {
    container: {
      position: "sticky",
      width: "300px",
      backgroundColor: "white",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      // padding: "16px 16px 32px 16px",
      ...style?.container,
    },
    section: {
      padding: "16px",
      borderBottom: "1px solid #D4D4D4",
      ...style?.section,
    },
    sectionNoBottom: {
      padding: "16px",
      ...style?.sectionNoBottom,
    },
    buttons: {
      marginTop: "32px",
      display: "flex",
      width: "100%",
      gap: "8px",
      ...style?.buttons,
    },
    heading: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "black",
    },
    smallHeading: {
      fontSize: "14px",
      fontWeight: "600",
      color: "black",
    },
    sectionHeadingContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    clearButton: {
      container: {
        padding: 0,
        fontSize: "14px",
        fontWeight: 400,
        color: "#2626CD",
      },
    },
  };

  const handleSearch = (value) => {
    setSearchInput(value);
  };

  const hasNameSet = searchInput !== "";
  const hasStatusSet = gameStatuses?.find((gameStatus) => gameStatus?.value === true);
  const hasRatingSet = ratings?.find((rating) => rating?.value === true);
  const hasListSet = lists?.find((list) => list?.value === true);
  const hasFollowedPlayerSet = followedPlayers?.find((player) => player?.value === true);
  const hasDate = dateMin !== null || dateMax !== null;
  // const hasDateSet = date?.find((rating) => rating?.value === true);

  const hasFilteredAnything =
    hasNameSet || hasStatusSet || hasRatingSet || hasDate || hasFollowedPlayerSet;

  const clearItem = (itemList) =>
    itemList?.map((item) => ({
      ...item,
      value: false,
    }));

  const clearAllFilters = () => {
    setSearchInput("");
    setGameStatuses(getDefaultGameStatusValues());
    setRatings(getDefaultRatingValues());
    setDateMax(null);
    setDateMin(null);
    setLists(clearItem(lists));
    setFollowedPlayers(clearItem(followedPlayers));
  };

  return (
    <div style={styles.container}>
      <div style={styles.section}>
        <div style={styles?.sectionHeadingContainer}>
          <div style={styles.heading}>Filter by:</div>
          {hasFilteredAnything && (
            <TextButton style={styles.clearButton} onClick={clearAllFilters}>
              Clear all filters
            </TextButton>
          )}
        </div>
      </div>
      <div style={styles.section}>
        <div style={{ ...styles?.sectionHeadingContainer, marginBottom: "8px" }}>
          <div style={styles.smallHeading}>Game name</div>
          {hasNameSet && (
            <TextButton style={styles.clearButton} onClick={() => setSearchInput("")}>
              Clear
            </TextButton>
          )}
        </div>
        <TextField
          style={{ label: { color: "black" } }}
          value={searchInput}
          onChange={handleSearch}
        />
      </div>
      <div style={styles.section}>
        <div style={styles?.sectionHeadingContainer}>
          <div style={styles.smallHeading}>Game status</div>
          {hasStatusSet && (
            <TextButton
              style={styles.clearButton}
              onClick={() => setGameStatuses(getDefaultGameStatusValues())}
            >
              Clear
            </TextButton>
          )}
        </div>
        <CheckboxGroup items={gameStatuses} setItems={setGameStatuses} />
      </div>
      <div style={styles.section}>
        <div style={styles?.sectionHeadingContainer}>
          <div style={styles.smallHeading}>Followed players</div>
          {hasFollowedPlayerSet && (
            <TextButton
              style={styles.clearButton}
              onClick={() => setFollowedPlayers(clearItem(followedPlayers))}
            >
              Clear
            </TextButton>
          )}
        </div>
        {followedPlayers?.length === 0 ? (
          <div style={{ fontSize: "12px", color: "#323232", marginTop: "4px" }}>
            Follow other players to see their reviews
          </div>
        ) : (
          <CheckboxGroup items={followedPlayers} setItems={setFollowedPlayers} />
        )}
      </div>
      <div style={styles.section}>
        <div style={styles?.sectionHeadingContainer}>
          <div style={styles.smallHeading}>Rating</div>
          {hasRatingSet && (
            <TextButton
              style={styles.clearButton}
              onClick={() => setRatings(getDefaultRatingValues())}
            >
              Clear
            </TextButton>
          )}
        </div>
        <CheckboxGroup items={ratings} setItems={setRatings} />
      </div>
      <div style={styles.section}>
        <div style={styles?.sectionHeadingContainer}>
          <div style={{ ...styles.smallHeading, marginBottom: "8px" }}>Date updated</div>
          {hasDate && (
            <TextButton
              style={styles.clearButton}
              onClick={() => {
                setDateMax(null);
                setDateMin(null);
              }}
            >
              Clear
            </TextButton>
          )}
        </div>
        <RangeDatePicker
          startDate={dateMin}
          endDate={dateMax}
          setStartDate={setDateMin}
          setEndDate={setDateMax}
          disabled={false}
        />
      </div>
      <div style={styles.sectionNoBottom}>
        <div style={styles?.sectionHeadingContainer}>
          <div style={styles.smallHeading}>My lists</div>
          {hasListSet && (
            <TextButton style={styles.clearButton} onClick={() => setLists(clearItem(lists))}>
              Clear
            </TextButton>
          )}
        </div>
        {lists?.length === 0 ? (
          <div style={{ fontSize: "12px", color: "#323232", marginTop: "4px" }}>
            Create lists to organise your games
          </div>
        ) : (
          <CheckboxGroup items={lists} setItems={setLists} />
        )}
      </div>
    </div>
  );
};
