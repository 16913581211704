import React, { useEffect, useState } from "react";
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateGameAction } from "../../data/actions/gamesAction";
import { getYears } from "../../data/lists";
import { Dropdown } from "../Dropdown/Dropdown";
import { ImageUpload } from "../ImageUpload/ImageUpload";
import { TextArea } from "../TextArea/TextArea";
import { TextField } from "../TextField/TextField";
import { Modal } from "./Modal";

const EditGameModal = ({
  style,
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  updateGame,
  games,
  game,
}) => {
  const [gameName, setGameName] = useState(game?.name);
  const [image, setImage] = useState(null);
  const [year, setYear] = useState(game?.year);
  const [description, setDescription] = useState(game?.description);

  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [yearError, setYearError] = useState("");

  const [posting, setPosting] = useState(false);

  // When opening, reset the state
  useEffect(() => {
    setGameName(game?.name);
    setImage(null);
    setYear(game?.year);
    setDescription(game?.description);
  }, [isOpen]);

  const handleSubmit = () => {
    // TODO validate fields
    let valid = true;
    if (!gameName) {
      setNameError("You must set a name for the game");
      valid = false;
    }

    // if (!image) {
    //   setImageError("You must upload an image");
    //   valid = false;
    // }

    const imageUploadLimit = 100 * 1000 * 1000; // 100MB TODO set this in env
    if (image && !image?.size >= imageUploadLimit) {
      setYearError("File size must be less than 100MB");
      valid = false;
    }

    if (!year) {
      setYearError("You must set a year for the game");
      valid = false;
    }

    if (!valid) {
      console.log("form is not valid");
      return;
    }

    updateGame(game?.id, {
      name: gameName,
      image,
      year,
      description,
    });

    setPosting(true);

    // TODO close the modal when game successfully updates
  };

  // Close the modal when the game is finished posting
  useEffect(() => {
    if (posting && !games?.posting && games?.success) {
      setPosting(false);
      closeModal();
    }
  }, [games?.posting, games?.success]);

  const isDisabled = () => games?.posting;

  const closeModal = () => {
    unstable_batchedUpdates(() => {
      setGameName("");
      setImage(null);
      setYear("");
      setDescription("");
      setNameError("");
      setImageError("");
      setYearError("");
      setPosting(false);
    });
    onClose();
  };

  // TODO show loading spinner while game is being created

  console.log("zgame", game);

  return (
    <Modal
      showCloseButton
      showCancelButton
      title="Edit game"
      submitText="Save changes"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      disabled={isDisabled()}
      loading={games?.posting}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <TextField
          label="Game name"
          placeholder="Game name"
          style={{ maxWidth: "400px" }}
          value={gameName}
          onChange={setGameName}
          error={nameError}
        />
        <ImageUpload
          value={image}
          placeholderImage={game?.artwork?.data?.url}
          onChange={setImage}
          label="Game image"
          style={{ maxWidth: "400px" }}
          error={imageError}
        />
        <Dropdown
          label="Year of release"
          style={{ maxWidth: "400px" }}
          value={year}
          onSelect={setYear}
          error={yearError}
          items={getYears()}
          placeholder="Select a year"
        />
        <TextArea
          label="Game description"
          placeholder="Paste the description..."
          value={description}
          onChange={setDescription}
          autofocus
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  games: state.games,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGame: updateGameAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditGameModal);
