import React, { useEffect, useState } from "react";
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createOrUpdateGameUserAction } from "../../data/actions/gameUsersAction";
import { createOrUpdateReviewAction } from "../../data/actions/reviewsAction";
import { ratings, statuses } from "../../data/lists";
import { formatDateForUpload, itemsAsValueList } from "../../helpers/helpers";
import { DatePicker } from "../DatePicker/DatePicker";
import { Dropdown } from "../Dropdown/Dropdown";
import { TextArea } from "../TextArea/TextArea";
import { TextField } from "../TextField/TextField";
import { Modal } from "./Modal";

const AddGameModal = ({
  style,
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  games,
  gameUsers,
  defaultGameId,
  gameUser,
  defaultStatus,
  createOrUpdateGameUser,
  createOrUpdateReview,
  reviews,
}) => {
  const [gameId, setGameId] = useState(defaultGameId);
  const [status, setStatus] = useState(defaultStatus);
  const [date, setDate] = useState(new Date());
  const [rating, setRating] = useState(null);
  const [hoursPlayed, setHoursPlayed] = useState(null);
  const [review, setReview] = useState(null);

  const [postingGameUsers, setPostingGameUsers] = useState(false);
  const [postingReview, setPostingReview] = useState(false);

  // When opening, reset the state
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setGameId(defaultGameId);
      setStatus(defaultStatus);
      setDate(new Date());
      setRating(null);
      setHoursPlayed(null);
      setReview(null);
      setPostingGameUsers(false);
      setPostingReview(false);
    });
  }, [isOpen]);

  // If default values update, then refresh those values
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setGameId(defaultGameId);
      setStatus(defaultStatus);
    });
  }, [defaultGameId, defaultStatus]);

  const closeModal = () => {
    unstable_batchedUpdates(() => {
      // TODO set everything back to default
      setPostingGameUsers(false);
      setPostingReview(false);
    });
    onClose();
  };

  const handleSubmit = () => {
    // TODO validate fields
    const valid = true;

    // TODO validation here

    if (!valid) {
      console.log("form is not valid");
      return;
    }

    // TODO create the status / review
    // Logic - create game user if doesn't exist
    // update game user if does exist (we should have an ID)
    // THEN if review ID is set, update the review (validate the rating is in the data too)
    // ELSE IF rating is set, upload a new review

    createOrUpdateGameUser({
      id: gameUser?.id,
      user_id: gameUser?.user_id,
      game_id: gameId,
      status,
      status_date: formatDateForUpload(date),
    });

    // const [rating, setRating] = useState(null);
    // const [hoursPlayed, setHoursPlayed] = useState(null);
    // const [review, setReview] = useState(null);

    setPostingGameUsers(true);
  };

  // Close the modal when the game is finished posting
  useEffect(() => {
    if (postingGameUsers && !gameUsers?.posting && gameUsers?.success) {
      setPostingGameUsers(false);

      if (rating) {
        createOrUpdateReview({
          game_user_id: gameUser?.id,
          status,
          rating,
          review_text: review,
          reviewed_at: formatDateForUpload(date),
        });

        setPostingReview(true);
      } else {
        closeModal();
      }
    }
  }, [gameUsers?.posting, gameUsers?.success]);

  // Close the modal when the review is finished posting
  useEffect(() => {
    if (postingReview && !reviews?.posting && reviews?.success) {
      setPostingReview(false);
      closeModal();
    }
  }, [reviews?.posting, reviews?.success]);

  const isDisabled = () => gameUsers?.posting;

  // TODO show loading spinner while posting

  // TODO make a useRef
  const gamesList = itemsAsValueList(games?.data || []);

  const isPosting = postingGameUsers || postingReview;
  const updating = !!gameUser?.id;

  return (
    <Modal
      showCloseButton
      showCancelButton
      title="Update status"
      submitText="Save changes"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      disabled={isDisabled()}
      loading={isPosting}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {gameUsers?.error || (reviews?.error && <div>{gameUsers?.error || reviews?.error}</div>)}
        <Dropdown
          label="Game name"
          style={{ maxWidth: "400px" }}
          value={gameId}
          onSelect={setGameId}
          items={gamesList}
          placeholder="Select a game"
          disabled={updating || isPosting}
          allowDeselect={false}
        />
        <Dropdown
          label="Status"
          style={{ maxWidth: "400px" }}
          value={status}
          onSelect={setStatus}
          items={statuses}
          disabled={isPosting}
          placeholder="Select a status"
        />
        <DatePicker
          label="Date"
          style={{ maxWidth: "400px" }}
          value={date}
          onSelect={setDate}
          disabled={isPosting}
        />
        {/* <Dropdown */}
        {/*  label="Date" */}
        {/*  style={{ maxWidth: "400px" }} */}
        {/*  value={date} */}
        {/*  onSelect={setDate} */}
        {/*  items={[ */}
        {/*    // TODO move this crap */}
        {/*    { label: "2022", value: 2022 }, */}
        {/*    { label: "2021", value: 2021 }, */}
        {/*  ]} */}
        {/*  placeholder="Select a month/year" */}
        {/* /> */}
        <Dropdown
          label="Rating (optional)"
          style={{ maxWidth: "400px" }}
          value={rating}
          onSelect={setRating}
          items={ratings}
          disabled={isPosting}
          placeholder="Select a rating"
        />
        <div style={{ display: rating ? "block" : "none" }}>
          <TextField
            label="Hours played"
            style={{ maxWidth: "400px" }}
            value={hoursPlayed}
            onChange={setHoursPlayed}
            disabled={!rating || isPosting}
            placeholder="Enter the hours played"
          />
        </div>
        <div style={{ display: rating ? "block" : "none" }}>
          <TextArea
            label="Comment / review (optional)"
            placeholder="Leave a review..."
            disabled={!rating || isPosting}
            value={review}
            onChange={setReview}
          />
        </div>
      </div>
    </Modal>
  );
};

AddGameModal.defaultProps = {
  defaultGame: null,
  defaultStatus: null,
};

const mapStateToProps = (state) => ({
  games: state.games,
  gameUsers: state.gameUsers,
  reviews: state.reviews,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateGameUser: createOrUpdateGameUserAction,
      createOrUpdateReview: createOrUpdateReviewAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddGameModal);
