import { mergeArrays, replaceItem } from "../../helpers/helpers";
import * as type from "../actions/types";

const initialState = {
  errors: [],
  loading: false,
  success: false,
  // apiData: {}, // The complete api response
  data: [], // Frontend's copy of the data, with modifications
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_USER_PENDING:
      return {
        ...state,
        ...initialState,
        loading: true,
        success: false,
        errors: [],
      };
    case type.FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        errors: [],
        // apiData: [...state.apiData, action.payload],
        data: replaceItem(state.data, action.payload, "id", true),
      };
    case type.FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload,
      };
    case type.FETCH_USERS_PENDING:
      return {
        ...state,
        ...initialState,
        fetching: true,
        success: false,
        errors: [],
      };
    case type.FETCH_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true,
        errors: [],
        data: mergeArrays(state.data, action.payload?.data, "id", true),
      };
    case type.FETCH_USERS_FAILURE:
      return {
        ...state,
        fetching: false,
        success: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
