import { mergeArrays, replaceItem } from "../../helpers/helpers";
import * as type from "../actions/types";

const initialState = {
  errors: [],
  fetching: false,
  posting: false,
  success: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_REVIEWS_PENDING:
      return {
        ...state,
        ...initialState,
        fetching: true,
        success: false,
        errors: [],
      };
    case type.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true,
        errors: [],
        data: mergeArrays(state.data, action.payload?.data, "id", true),
      };
    case type.FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        fetching: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_REVIEW_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.CREATE_REVIEW_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.UPDATE_REVIEW_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.UPDATE_REVIEW_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_REVIEW_SUCCESS:
      console.log("Review was created", action?.payload, state);
      const newItem = action?.payload?.data;

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, newItem, "id", true),
      };
    case type.UPDATE_REVIEW_SUCCESS:
      console.log("Review was updated", action?.payload, state);
      const updatedItem = action?.payload?.data;

      // const updatedReviews = (state.data?.data || [])?.map((review) => {
      //   if (updatedItem?.id === review?.id) {
      //     return {
      //       ...review,
      //       ...updatedItem,
      //     };
      //   }
      //
      //   return review;
      // });

      const updatedReviews = replaceItem(state.data, updatedItem, "id", true);

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: updatedReviews,
      };
    default:
      return state;
  }
};
